import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import styles from './ProductImage.module.scss';

export const ProductImage =({
    imgSrc,
    imgTitle="",
    imgAlt="photo of the product",
    shape="",
    size="",
    alignment="center",
    ...props
})=>{

    const [ zoomModal, setZoomModal ] = useState(false);

    if(!imgSrc) return null

    return(
        <div 
            data-cy="product-card-image"
            className={`
                ${styles["img-div"]}
                ${styles[alignment]}
            `}
            >
            <img 
                onClick={()=>setZoomModal(true)}
                src={imgSrc} 
                alt={imgAlt} 
                title= {imgTitle} 
                className={`
                    ${styles["pos-img"]} 
                    ${shape === "circle" ? styles.circle : ""} 
                    ${size ==="large" ? styles.large : ""}
                    ${size === "medium" ? styles.medium : ""}
                `}
            />

            <Modal show={zoomModal} onHide={()=>setZoomModal(false)} backdrop={true}>
                <Modal.Body>
                    <img
                        src={imgSrc}
                        alt={`Full Sized - ${imgAlt}`}
                    />
                </Modal.Body>
            </Modal>
        </div>
    )
}
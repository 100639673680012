import React, {useState, useCallback, useEffect, useMemo, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Row, Col, Container, Modal, Form} from 'react-bootstrap';

import Toast from '../../../components/Toast';
import Totals from './Totals';
import Buttons from './Buttons';
import PaymentType from './PaymentType';
import Tip from './Tip';
import Success from './Success';

import APIPOS from '../../../api/Pos';
import {refreshOrder} from '../../../utils/thunks';
import { generateHash } from '../../../utils/cms';
import { base64ToFile } from '../../../utils/pos';

import * as actions from '../../../store/actions';
import styles from './Checkout.module.scss';


export const Checkout = (props) => {
    const {onClose} = props;

    const dispatch = useDispatch();    
    const memoRef = useRef();
    const intervalRef = useRef();
    const firstLoad = useRef(true);

    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [submitting, setSubmitting] = useState(false);
    const [payments, setPayments] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
    //const [totals, setTotals] = useState({admin_fee: 0, total: 0, paid: 0, change: 0, balance: 0, to_be_paid: 0, estimated_balance: 0});
    const [currentTransaction, setCurrentTransaction] = useState();
    const [forEnableCompleteOrder, setForEnableCompleteOrder] = useState(false);

    const order = useSelector(state => state.pos[props.register_id].orderAll);
    const user = useSelector(state => state.pos[props.register_id].user);

    const checkTrx = useCallback(async () => {
        if (!currentTransaction) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            return false;
        }

        const cleanup = () => {
            setSubmitting(false);
            clearInterval(intervalRef.current);
            intervalRef.current = null;
            setCurrentTransaction(null);
        }

        //console.log("waiting")

        setSubmitting(true);
        const transaction = {...currentTransaction};
        if (+transaction.transaction_status_id === 6 && transaction.id){
            let data = {};
            let _status = 6;
            try{
                let res2=await APIPOS.payment.terminalStatus({transaction_id: transaction.id});
                if (res2.errors){
                    _status = 4 // canceled
                    setError(res2.errors);
                    if (res2.errors?.transaction?.success === false) _status = 8; // failed
                }
                if (res2.data?.[0]?.transaction_status_id!==6){
                    _status = +res2.data[0]?.transaction_status_id;
                    if (_status ===8) {
                        setError("Transaction Failed.");
                        cleanup();
                    } else if (_status === 4) {
                        setError("Transaction Canceled.");
                        cleanup();
                    }
                }

                if (_status === 7){
                    data = {...res2.data[0]};
                    data.transaction_status_id = _status;
                    data.transaction_id = transaction.id;
                    setError(null);
                    setSuccess([`Payment Successful!`, res2.data]);
                    cleanup();
                    return data;
                }
            } catch(err){
                setError("An error ocurred. Transaction Failed.");
                cleanup();
                return null;
            }
        }
        return false;
    }, [currentTransaction]);

    const totals = useMemo(() => {
        const _payments = [...payments];

        let to_be_paid = 0, balance =0, estimated_balance = 0, final_total = 0, change = 0, admin_fee = 0, tip = 0, just_cash = true;
        if (!selectedPaymentMethod || +selectedPaymentMethod !== 2) just_cash = false;

        _payments.forEach(p=>{
            to_be_paid += +p.amount.toFixed(2);
            if (+p.payment_method_id !== 2) just_cash = false;
        });

        if (just_cash){ 
            if (order?.transactions){
                for (const t of order.transactions){
                    if (+t.transaction_payment_method_id !== 2) {
                        just_cash = false;
                        break;
                    }
                }
            } else if (_payments.length <= 0 && +selectedPaymentMethod !== 2) just_cash = false;
        }

        admin_fee = +(order?.calculated_cash_discount || 0) * (just_cash ? -1 : 0) * (order?.price_adjustments?.length > 0 ? 0 : 1);

        final_total =( order?.total_price || 0 ) + admin_fee; //+ (order?.tip || 0) ;
        //if (just_cash) final_total -= order?.admin_fee_total || 0;

        estimated_balance = final_total.toFixed(2) - (order?.payment_total?.toFixed(2) || 0) - to_be_paid;
        if (estimated_balance < 0) estimated_balance = 0;

        balance = +final_total.toFixed(2) - (order?.payment_total?.toFixed(2) || 0);
        if (balance < 0) {
            if (order?.transactions){
                for (const t of order.transactions){
                    if (t.change > 0) {
                        balance += +t.change;
                    }
                }
            }
        }

        change = +to_be_paid - +balance;
        /*if (order?.order_status_id === 2) { // we override the change to show the final change if the order is complete, if not, we show the change based on the payments being made
            change = +(order?.payment_total?.toFixed(2) || 0) - final_total;
        }*/
        if (change < 0) change = 0;

        setSubmitting(false);

        return ({
            admin_fee: +admin_fee.toFixed(2),
            total: +final_total.toFixed(2),
            net_balance: +balance - (+order?.tip || 0),
            paid: +order?.payment_total || 0, 
            change,
            balance,
            to_be_paid,
            estimated_balance,
            just_cash,
        });
        
    }, [order?.total_price, order?.payment_total, order?.calculated_cash_discount, order?.transactions, order?.price_adjustments, order?.tip, payments, selectedPaymentMethod]); 
    
    const closeHandler = useCallback(e => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        dispatch(actions.setTip(0, props.register_id));
        //APIPOS.local.remove(`POS-${props.register_id}`);
        //APIPOS.local.remove(`POS-${props.register_id}-order-id`);
        onClose();
    }, [onClose, dispatch, props.register_id]);

    const saveHandler = useCallback(async e => {
        e.preventDefault();
        e.stopPropagation();

        await APIPOS.local.remove(`POS-${props.register_id}`);
        await APIPOS.local.remove(`POS-${props.register_id}-order-id`);
        
        let res = await APIPOS.local.get(`POS-NUMTABS-${props.register_id}`);
        if (res){
            if (!Array.isArray(res)) res=[res];
            const new_numtabs = res.filter(a => +a.id !== +order?.id);
            APIPOS.local.save(`POS-NUMTABS-${props.register_id}`, new_numtabs);
        }
        dispatch(actions.reset(props.register_id));
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
        onClose();
    }, [order?.id, dispatch, props.register_id, onClose]);

    const completeHandler = useCallback(async e => {
        e.preventDefault();
        e.stopPropagation();

        if (selectedPaymentMethod) {
            let _amount = totals.balance;
            let _success = null, _error = null, _data = null, _files = null, _payments = [];
    
            setSubmitting(true);
            setError(null);
            //setCurrentTransaction(null);
            switch (selectedPaymentMethod) {
                case "TERMINAL": // terminal goes by itself (because we need to wait for the hardware to respond)
                    _payments = payments.filter(a=>a.payment_method_id === selectedPaymentMethod);
                    if (_payments.length > 0) {
                        _amount = +_payments[_payments.length - 1].amount; // get the last one
                        if (_amount <=0) _amount = +totals.balance;
                        try{
                            const res = await APIPOS.payment.terminal({
                                poi_device_id: props.terminal_device_id,
                                type: "sale",
                                user_id: order.user_id,
                                order_id: order.id,
                                location_id: order.location_id,
                                store_payment_info: 1,
                                amount: +_amount,
                                memo: memoRef.current?.value || "",
                            });

                            if (res?.errors) _error = res.errors;
                            else if (res?.data?.length) {
                                _error = null;
                                setCurrentTransaction(res.data[0]);
                                //_success=`Transaction #${res.data[0]} Successful!`;
                                setPayments(prev => {
                                    const _prev = [...prev];
                                    const index = _prev.findIndex(a=>a.tmp_id === _payments[_payments.length - 1].tmp_id);
                                    if (index >= 0) {
                                        if (res?.data?.[0]) _prev[index].transaction_id = res.data[0];
                                        else if (res?.errors) _prev.splice(index, 1);
                                    }
                                    return [..._prev];
                                });
                            }
                        } catch(err){
                            _error = err.message || err || "An error ocurred. Please try again.";
                        }
                    } else _error = "Please enter an amount.";
                    break;
                case 1: // credit card goes by itself (because of the nmi token)
                    _payments = payments.map((a, i) => ({tmp_id: i, ...a})).filter(a=>a.payment_method_id === selectedPaymentMethod);
                    if (_payments.length > 0) {
                        const idx = _payments.length - 1; // get the last one
                        _amount = +_payments[idx].amount;
                        if (_amount <=0) _amount = +totals.balance;

                        const res2 = await Promise.all([
                            //_payments[0].collectJS.retokenize(),
                            _payments[0].collectJS.startPaymentRequest(),
                            _payments[0].collectJS.tokenPromise,
                        ]);
                        if (res2[1].token) {
                            _data = [{
                                payment_method_id: selectedPaymentMethod,
                                amount: _amount,
                                type: 'sale',
                                description: "Online Credit Card Payment",
                                payment_token: res2[1].token,
                                first_name: _payments[idx].first_name || user.first_name,
                                last_name: _payments[idx].last_name || user.first_name,
                                bill_address1: _payments[idx]?.bill_address1 || undefined,
                                bill_address2: _payments[idx].bill_address2 || undefined,
                                bill_city: _payments[idx].bill_city || undefined,
                                bill_state: _payments[idx].bill_state || undefined,
                                bill_postalcode: _payments[idx].bill_postalcode || undefined,
                                payment_profile_id: _payments[idx].payment_profile_id || undefined,
                            }];
                        } else {
                            _error = "An error ocurred. Please try again.";
                        }
                    } else _error = "An error ocurred. Please try again.";
                    break;
                case 5: // manager discount goes by itself
                    _payments = payments.map((a, i) => ({tmp_id: i, ...a})).filter(a=>a.payment_method_id === selectedPaymentMethod);
                    if (_payments.length > 0) {
                        const idx = _payments.length - 1; // get the last one
                        _amount = +_payments[idx].amount;
                        if (_amount <=0) _amount = +totals.balance;

                        _data = [{
                            payment_method_id: selectedPaymentMethod,
                            amount: _amount,
                            username: _payments[idx].username, 
                            password: _payments[idx].password,
                        }];
                    } else _error = "Please fill all the fields and try again.";
                    break;
                default: // everything else goes in a batch
                    _payments = payments.filter(a=>a.payment_method_id !== "TERMINAL" && a.payment_method_id !== 1 && a.payment_method_id !== 5); // filter out the guys that go by themselves
                    
                    // if there is no payment but the selected payment method is cash, it means the user just clicked the button to complete the order, so we set up the payment
                    if (_payments.length <= 0 && selectedPaymentMethod === 2){
                        _payments = [{tmp_id: 1, payment_method_id: 2, payment_method_name: "Cash Tendered", amount: +totals.balance}];
                    }

                    // sets up the data to be sent
                    if (_payments.length > 0) {
                        _data = [..._payments];
                        /* 
                        // set up images to be sent
                        _files = new FormData();
                        for (const p of _data) {
                            for (const key in p) {
                                if (p[key]) {
                                    let value = p[key];
                                    if (key === "image") {
                                        value = await base64ToFile(p[key], 'check.jpg');
                                        _files.append('files', value);
                                        delete p[key];
                                    }
                                }
                            }
                        }
                        */
                    } else _error = "An error ocurred. Please try again.";
                    break;
            }

            if (selectedPaymentMethod !== "TERMINAL"){
                if (_data){
                    try{
                        const res3 = await APIPOS.payment.process({
                            order_id: order.id,
                            hash: generateHash(_data[0]) || undefined,
                            payments: _data,
                            files: _files,
                            memo: memoRef.current?.value || undefined,
                        });
                        if (res3?.errors) _error = res3.errors;
                        else if (res3?.data || (order?.total_price === 0 && _data[0]?.amount === 0)) {
                            if (!Array.isArray(res3.data)) res3.data=[res3.data];
                            _error = null;
                            _success=[`Payment Successful!`, res3.data];
                            if (order.estimated_balance <= 0) {
                                APIPOS.local.remove(`POS-${props.register_id}`);
                                APIPOS.local.remove(`POS-${props.register_id}-order-id`);
                            }
                        }
                    } catch(err){
                        _error = "An error ocurred. Please try again.";
                    }
                } else {
                    _error = "An error ocurred. Please try again.";
                }
            }

            if (_success) setSuccess(_success);
            if (_error) setError(_error);
            if (_success || _error) setSubmitting(false);
        }
    }, [order, totals, user, payments, selectedPaymentMethod, props.register_id, props.terminal_device_id]);

    const tipChangeHandler = useCallback((tip, fixed = false) => {
        let tip_amount = order.subtotal_price * (+tip / 100);
        if (fixed) tip_amount = +tip;
        dispatch(actions.setTip(+tip_amount, props.register_id));
        let resetPayments = false;
        setPayments(prev => {
            if (prev.length > 1) { // resets the payments, because we need to recalculate the totals
                resetPayments = true;
                return []; 
            } else if (prev.length === 1) {
                return [{...prev[0], amount: +totals.net_balance + +tip_amount}];
            }
            return prev;
        });
        if (resetPayments) setSelectedPaymentMethod(null);
    }, [order, dispatch, totals, props.register_id]);

    const paymentUpdateHandler = useCallback((payment_method_id, data = [], callback) => {
        setPayments(prev => {
            let _paid = 0;
            let _prev = [...prev].filter(a=>a.payment_method_id !== payment_method_id);

            if (payment_method_id === "TERMINAL" || payment_method_id === 1 || payment_method_id === 5) _prev = []; // these one go by themselves, so we reset the array

            let i = 0;
            for (let p of data) {
                if (+p.amount > 0) {
                    i++;
                    _prev.push({tmp_id: i, payment_method_id, ...p, amount: +p.amount});
                    _paid += +p.amount;
                }
            }

            // reorder the array so that payment_method_id 2 (cash) is always the last, and payment_method_id 4 (gift cards) is always first
            _prev.sort((a,b)=>{
                if (+a.payment_method_id === 2) return 1;
                else if (+b.payment_method_id === 2) return -1;
                else if (+a.payment_method_id === 4) return -1;
                else if (+b.payment_method_id === 4) return 1;
                else return 0;
            });

            return [..._prev];
        });
    }, []);

    /*useEffect(()=>{
        calculateTotals();
    }, [calculateTotals]);*/

    useEffect(()=>{
        if (currentTransaction && !intervalRef.current) {
            intervalRef.current = setInterval(checkTrx, 2500);
        }
    }, [checkTrx, currentTransaction]);

    useEffect(()=>{
        if (success){
            setPayments([]);
            setSelectedPaymentMethod(null);
            //setTotals(prev=>({...prev, to_be_paid: 0}));
            dispatch(refreshOrder(props.register_id));
        }
    }, [success, dispatch, props.register_id]);

    useEffect(()=>{
        firstLoad.current = false;
        return () => {
            setPayments([]);
            setSelectedPaymentMethod(null);
            //setTotals({total: 0, paid: 0, balance: 0, to_be_paid: 0});
        }
    }, []);

    if (!order) {
        //onClose();
        return null;
    }
 
    return (
        <Modal show={props.show} keyboard={false} size="xl" backdrop="static" onHide={onClose}>
            <Modal.Header />
            <Modal.Body className="p-0">
                <Container fluid>
                {order.order_status_id === 2 &&
                    <Row className="h-100 w-100">
                        <Col className={styles.options}>
                            <Success {...props} success={success} order={order} />
                        </Col>
                    </Row>
                }
                {order.order_status_id !== 2 &&
                    <>
                        {success &&
                            <Toast>{success?.[0]}</Toast>
                        }                        
                        <Row className="m-0 h-100 w-100">
                            <Col sm={12} lg={4} className={styles.totals}>
                                <Totals {...props} totals={totals} order={order} payments={payments} user={user} />
                            </Col>
                            <Col className={styles.options}>
                                <Tip {...props} order={order} submitting={submitting} onTipChange={tipChangeHandler} />
                                <br/>
                                <PaymentType 
                                    {...props} 
                                    order={order} 
                                    success={success} 
                                    payments={payments} 
                                    totals={totals} 
                                    submitting={submitting} 
                                    selectedPaymentMethod={selectedPaymentMethod}
                                    //calculateTotals={calculateTotals} 
                                    setPayments={setPayments} 
                                    onPaymentUpdate={paymentUpdateHandler} 
                                    onPaymentMethodChange={setSelectedPaymentMethod} 
                                    setForEnableCompleteOrder={setForEnableCompleteOrder}
                                    setSuccess={setSuccess}
                                />
                                {error && <div className="error-text mb-3">{error}</div>}
                                <hr/>
                                <Form.Group controlId="memo">
                                    <Form.Label>Memo</Form.Label>
                                    <Form.Control ref={memoRef} type="text" name="memo" defaultValue={order?.memo || ""} disabled={submitting} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </>
                }
                </Container>
            </Modal.Body>
            <Modal.Footer className="p-0">
                <Buttons 
                    {...props} 
                    totals={totals} 
                    submitting={submitting} 
                    orderStatus={order.order_status_id} 
                    onSave={saveHandler} 
                    onClose={closeHandler} 
                    onComplete={completeHandler} 
                    selectedPaymentMethod={selectedPaymentMethod}
                    forceEnable={forEnableCompleteOrder}
                />
            </Modal.Footer>
        </Modal>
    );
}
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
import { format } from 'date-fns';
import { Button, Modal } from 'react-bootstrap';
import { numberOfTokens, selectTimeSlot } from '../../../store/selectors';

import Payment from '../Payment';
import Checkout from '../Checkout';
import {confirm} from '../../../components/Confirmation';
import {Coupons as CouponPicker} from './Coupons';
import Pos from '../../../api/Pos';
import * as actions from '../../../store/actions';
import { convertMinutesToHoursDays } from '../../../utils/dates.js';
import { cancelBooking, updateCart, addToCart, saveLogging } from '../../../utils/thunks';
import { stringToUUID } from '../../../api/Common';

import "./Preview.scss";

const ORDER_STATUSES = {
    1: "Not Paid",
    2: "Completed",
    3: "Cancelled",
    4: "Refunded"
}

export const Preview = (props) => {
    const {show_checkout_btn = true, is_patron_register = false} = props;

    let history = useHistory();
    const dispatch = useDispatch();

    const defaultPatronRegisterId = useSelector(state => state.pos.register)
    const additionalClass = props?.print ? "receipt-container-print": "";

    const register = props.register_id || defaultPatronRegisterId;
        
    const transaction=useSelector(state => state.pos[register].transaction);
    const order_id = useSelector(state => state.pos[register].order);                 // int
    const order = useSelector(state => state.pos[register].orderAll);                 // object
    const order_imported=useSelector(state => state.pos[register].order_imported);    // int
    const order_status=useSelector(state => state.pos[register].order_status);        // int
    const tax_rate=useSelector(state => state.pos[register].tax_rate) || 0;
    const adminfee_rate=useSelector(state => state.pos.adminfee_rate);

    const aitems = useSelector(state => state.pos[register].items);
    const customer = useSelector(state => state.pos[register].user);

    const currentBooking = useSelector(state => state.serviceBooking.current_booking);
    const serviceNumberOfTokens = useSelector(numberOfTokens);
    const selectedTimeslot = useSelector(selectTimeSlot);

    const [serviceShowBookingEvent, setServiceShowBookingEvent] = useState(false);
    const [serviceEventPurchaseItem, setServiceEventPurchaseItem] = useState(<></>);
  
    const [couponModalShow,setCouponModalShow] = useState(false);

    const [allItemsPagePart, setAllItemsPagePart] = useState(<></>);
    const [contentPagePart, setContentPagePart] = useState(<></>);
    const [ collapseState, setCollapseState ]=useState("open");

    // checkout button

    const [showPayment, setShowPayment] = useState(false);

    const paymentModalCloseHandler = (e) => {
        setShowPayment(false);
    }

    useEffect(() => {
        let mounted = true;

        const onCancelBooking = () => {
            dispatch(cancelBooking(register));
        }

        let fauxItem = (<></>);
        if (currentBooking?.event_id && currentBooking?.service?.default_product_variant_id) {
            let tokenProducts = aitems.filter(item => item.variant_id === currentBooking.service.default_product_variant_id);
            let numberTokenProducts = tokenProducts.length;
            let tokenProductPrice = +tokenProducts[0]?.product_price;

            if (numberTokenProducts>0) {
                // There is a Service Booking
                // create the faux service event to show
                setServiceShowBookingEvent(true);
                fauxItem = (
                    <div className="row-wrapper" data-cy="preview-item" key={`product-line-service-booking`}>
                        <div className="product-name">
                            Service Booking: {currentBooking.service.name}<br />
                            {convertMinutesToHoursDays(selectedTimeslot.duration+1)} - {numberTokenProducts} timeslots
                        </div>
                        <div className="item-price">
                            <span>${(numberTokenProducts * tokenProductPrice).toFixed(2)}</span>
                        </div>
                        <div className="product-buttons">
                            <Button variant="light" className="btn-remove" data-cy="delete-prod-btn" onClick={() => onCancelBooking()}>
                                <i className="far fa-minus"></i>   
                            </Button> 
                        </div>
                    </div>
                );
            }
        }
        setServiceEventPurchaseItem(fauxItem);

        return () => {
            mounted = false;
        }
    },[aitems, currentBooking, dispatch, register, selectedTimeslot.duration]);

    const handleCouponClose = (e) => {
        setCouponModalShow(false);
    }

    const clickHandler = useCallback(item => {
        if (item.type===0){
            dispatch(actions.activeItem({
                id:item.id,
                name:item.product_name,
                type:item.type,
                is_taxable:item.is_taxable,
                hash:item.hash,
                product_id:item.product_id,
                variant_id: item.variant_id,
                addons: item.addons
            }, register));
            dispatch(actions.triggerPicker(2, register)); // trigger the addon picker in the main window
        }
    },[ dispatch, register]);

    const confirmDeleteHandler = useCallback(
        (props) => {
            confirm(props.text,{
                title:"Whoa!",
                okText:"Yes",
                cancelText:"No",
                cancelButtonStyle:"light"
            }).then(result =>{
                if (result===true) props.click();
            });
        },[]
    );

    const copyProduct=useCallback((item)=>{
        let newHash=stringToUUID(Math.random());
        replaceAddOnOrderItemId(item.addons)
        let newItem={...item};
        newItem.hash = newHash;
        newItem.id=null;
        newItem.order_item_id=null;
        dispatch(actions.addPOSItem(newItem, register));
        //console.log("dispatch updateCart Preview 155");
        dispatch(saveLogging(`dispatch updateCart Preview 155`));
        dispatch(updateCart(register))
    },[dispatch, register]);

    const replaceAddOnOrderItemId=(addons)=>{
        addons.forEach((addon)=>{
            let newHash=stringToUUID(Math.random());
            addon.order_item_id = null
            addon.id = null
            addon.hash = newHash
        });
    }

    const currentCustomer = useMemo(() => {
        return (
            <div className="customer-wrapper">
                <span className="current-customer">
                    Customer: {" "}
                        {customer ? 
                            <span>{customer.first_name + " " + customer.last_name}</span>
                        :
                            <span>GUEST USER</span>
                        }
                </span>
                {customer &&
                    <Button className="btn btn-primary clear-customer" onClick={() => dispatch(actions.resetCustomer(register))} data-cy="preview-clear-user-btn">Clear User</Button>
                }
            </div>
        );
    },[customer, dispatch, register]);

    const closeClickHandler= useCallback(() => {
        Pos.local.remove(`POS-${register}-order-id`)
        .then(async response => {
            dispatch(actions.reset(register));
        });
    },[dispatch, register]);

    const currentOrder = useMemo(() => {
        if (order_id && !props.cart_layout) {
            return (
                <div className="order-title">
                    <h4 className="order-number">Order #<span data-cy="order-number">{order_id}</span></h4>
                    <Button className="btn btn-primary" data-cy="order-clear-customer-btn" onClick={closeClickHandler}>Close Order</Button>
                </div>
            );
        } else return null;
    },[order_id, closeClickHandler, props.cart_layout]);

    const formatOrderItem = useCallback((item, parentItem) => {
        let isAddon = !!parentItem;
        if(item.id){
            return (
                <div key={`item-${item.id}-${new Date()}`} className={`row-wrapper ${isAddon && 'no-top-border'}`} data-cy="preview-item">
                    <div className='item-name' onClick={()=>clickHandler(isAddon ? parentItem : item)}>
                        <div>   {/* this exists to vertically center the text in the grid cell */}
                            {isAddon && 
                                <span className="addon-dot"><i className="far fa-circle"></i></span>
                            }
                            <span data-cy="product-name">{item.product_name}</span>
                            {!props.cart_layout && !isAddon && item.product_name!==item.variant_name
                                    && item.variant_name.trim().toLowerCase()!==item.product_name.trim().toLowerCase()
                                    && item.variant_name.trim().toLowerCase()!=="default"
                                    && item.variant_name.trim().toLowerCase()!=="" &&
                                <>
                                    {" "}-{" "}<span data-cy="variant-name">{item.variant_name}</span>
                                </>
                            }
                            {item.hasOwnProperty("bill_interval") && item.hasOwnProperty("interval_quantity") &&
                                <div className="small">
                                    {+item.activation_fee>0 && `+$${item.price} `}
                                    {item?.bill_interval ==="m" && item.interval_quantity ? `Billed every ${item.interval_quantity} month${item.interval_quantity > 1 ? "s" : ""} ${item.bill_num_times ? (item.bill_num_times > 1 ? `${item.bill_num_times} times in total` : "") : "until cancelled"}` : ""}
                                    {item?.bill_interval ==="y" ? `${item.bill_num_times ? "Billed once" : "Billed annually" }` : ""}
                                </div>
                            }
                            {item.hasOwnProperty("subscription_type_name") && item?.subscription_type_name && 
                                <div className="small">
                                    {item.subscription_type_name} Subscription 
                                    {item.hasOwnProperty("subscription_max_users") && item?.subscription_max_users > 1 &&
                                        <>
                                          {" "} ({item.subscription_max_users} Users Max)
                                        </>
                                    }
                                </div>
                            }
                            {item.event &&
                                <ul>
                                    <li>For {item.event.for_user_name}</li>
                                    {item.event?.custom_fields && Object.keys(item.event.custom_fields).map((key,i)=>{
                                        if (item.event?.custom_field_definition){
                                            let fieldDefinition = item.event.custom_field_definition.find(field=>field.name===key);
                                            if (fieldDefinition){
                                                return (
                                                    <li key={`custom-field-${i}`} className="small">{fieldDefinition.placeholder_text}: {item.event.custom_fields[key]}</li>
                                                );
                                            }
                                        }
                                        return null;
                                    })}
                                </ul>
                            }
                            {item.giftcard &&
                                <ul>
                                    <li>For {item.giftcard.full_name}</li>
                                    <li>{item.giftcard.email}</li>
                                    <li  className="small">Deliver on {new Date(item.giftcard.delivery_date).toLocaleDateString("en-US",{weekday:"long",day:"2-digit",month:"2-digit",year:"numeric"})}</li>
                                </ul>
                            }
                        </div>
                    </div>
                    <div className='item-price' onClick={()=>clickHandler(isAddon ? parentItem : item)}>
                        {+item.qty>1 && 
                        <span>({(item.qty).toFixed(0)}){" "}</span>
                        }
                        <div className="multiple-lines">
                            {+item.product_price < +item.original_price &&
                                <span data-cy="original-price" className='original-price' style={{color: "red"}}>${(+item.original_price).toFixed(2)}</span>
                            }
                            <span data-cy="product-price">${(+item.product_price).toFixed(2)}</span>
                        </div>
                    </div>
                    <div className='product-buttons'>
                        {+order_status===1 &&
                            <>
                                {!isAddon && !is_patron_register &&
                                    <Button variant="light" className="copy-prod-btn" data-cy="copy-prod-btn" onClick={()=>copyProduct(item)}>
                                        <i className="far fa-copy" />
                                    </Button>
                                }
                                <Button variant="light" className="btn-remove" data-cy="delete-prod-btn" data-id={item.id} data-variant={item.variant_id} data-hash={item.hash} data-type={item.type}
                                    onClick={(e) => {
                                    if (+item.qty-1<=0){
                                        confirmDeleteHandler({
                                            text: register !== defaultPatronRegisterId ? `Are you sure you want to delete this line?` : `Are you sure you want to remove this item?`,
                                            click:()=>{
                                                dispatch(actions.removePOSItem(e.target.dataset, register));
                                                //console.log("dispatch updateCart Preview 257");
                                                dispatch(saveLogging(`dispatch updateCart Preview 257`));
                                                dispatch(updateCart(register));
                                            }
                                        });
                                    }
                                    }}>
                                    <i className="far fa-minus" data-id={item.id} data-variant={item.variant_id} data-hash={item.hash} data-type={item.type}></i>
                                </Button>
                                
                            </>
                        }
                    </div>
                </div>
            );
        }
    },[is_patron_register, defaultPatronRegisterId, props, clickHandler, confirmDeleteHandler, dispatch, order_status, register, copyProduct]);


    useEffect(() => {
        let displayItems = [];

        if (aitems?.length>0) {
            let tokenCounter = 0;
            let serviceDisplayed = false;

            setAllItemsPagePart(<>
                {aitems.map((item,i) => {
                    let isHidden = false;
                    if (serviceShowBookingEvent && item.variant_id === currentBooking?.service?.default_product_variant_id) {
                        isHidden = true;
                        tokenCounter++;
                    }
                    
                    // {/* display the faux service event right before the related tokens */}
                    let display = (<></>);
                    if (serviceShowBookingEvent && tokenCounter===1 && !serviceDisplayed) {
                        display = (<>{serviceEventPurchaseItem}</>);
                        serviceDisplayed = true;
                    }
                    let isAddon = item.parent_id && (item.parent_id!==item.product_id || !item.product_id);

                    if (!isAddon) {
                        return(
                            <React.Fragment key={`product-line-${i}`}>
                                {display}
                                <div className={`group-wrapper ${isHidden && 'hidden'}`}>
                                    {formatOrderItem(item, null)}
                                    {item.addons.map(addonItem => formatOrderItem(addonItem, item))}
                                </div>
                            </React.Fragment>
                        );
                    }
                    return null;
                })}
            </>);
        } else {
            setAllItemsPagePart(<></>);
        }
    },[aitems, register, serviceShowBookingEvent, currentBooking, serviceEventPurchaseItem, formatOrderItem]);

    const itemsGridPagePart = useMemo(() => 
        <section className="preview-items-grid-wrapper">
            <div className="preview-items-grid receipt-table">
                <div className="row-wrapper">
                    <div className="grid-header">Item Name</div>
                    <div className="grid-header item-price">Price</div>
                    <div className="grid-header"></div>
                </div>

                {allItemsPagePart}

            </div>  {/* end grid */}

            {aitems.length===0 && 
                <div className="no-items">*** No items added. ***</div>
            }

        </section>
    ,[allItemsPagePart, aitems]);

    const orderStatusPagePart = useMemo(() => {
        if (order_imported===true && order_status && ORDER_STATUSES[order_status]) {
            return (
                <h5 className="order-status">{ORDER_STATUSES[order_status]}</h5>
            );
        } else {
            return (<></>);
        }
    },[order_imported, order_status]);

    const orderDiscountsPagePart = useMemo(() => {
        if (order?.coupons?.length === 0) {
                return (<div className="customer-wrapper" data-cy="order-no-discounts">
                    <span>Discounts:</span>
                    <div className="no-discounts-found">No compatible discounts available.</div>
                </div>);
        } else if ( order?.coupons?.length > 0) {
            return (
                <div className="customer-wrapper discount-wrapper">
                    <span className="bold">Discounts: </span>
                    <Button variant="light" className="btn clear-customer d-flex justify-content-end" data-cy="order-discount-link" onClick={e=>setCouponModalShow(true)}>
                        Select
                    </Button>
                    {order?.coupons_applied?.map((coupon)=>(
                        <div style={{flex: "0 0 100%"}} key={`coupon-${coupon.id}`}>{coupon.name}</div>
                    ))}
                </div>
            );
        }
        return (<></>);
    },[order]);

    const footerPagePart = useMemo(() => {
        return (
            <section className="footer-grid">
                <div className="row-wrapper">
                    <div>Subtotal</div>
                    <div data-cy="order-subtotal">${(+order?.subtotal_price || 0).toFixed(2)}</div>
                </div>
                {!props.cart_layout &&
                    <div className="row-wrapper">
                        <div>Tax ({tax_rate?.toFixed(1)}%)</div>
                        <div data-cy="order-tax">${(+order?.tax_total || 0).toFixed(2)}</div>
                    </div>
                }
                {order?.price_adjustments?.map((adjustment, i) =>{
                    if ((Math.abs(+adjustment?.amount) || 0) <= 0) return null;                    
                    return (
                        <div className="row-wrapper" key={`pos-totals-pa-${i}`}>
                            <div>{`${adjustment.price_adjustment_type_name} ${+adjustment.percentaje > 0 ? `(${adjustment.percentaje.toFixed(2)}%` : ""}`}</div>
                            <div data-cy="order-admin-fee">
                                {+adjustment?.amount > 0 ? "" : "-"}
                                ${Math.abs(+adjustment?.amount || 0).toFixed(2)}
                            </div>
                        </div>
                    );
                })}
                {!props.cart_layout &&
                    <div className="row-wrapper totals">
                        <div>Total</div>
                        <div data-cy="order-total">${(+order?.total_price || 0)?.toFixed(2)}</div>
                    </div>
                }
                {props.payment_cash && 
                    <>
                        <div className="row-wrapper">
                            <div className="twi1"><h5>Payment</h5></div>
                            <div className="text-right twi2"><h5>${+props.payment_cash.toFixed(2)}</h5></div>
                        </div>
                        <div className="row-wrapper">
                            <div className="twi1"><h5>Change</h5></div>
                            <div className="text-right twi2"><h5>${+props.payment_change.toFixed(2)}</h5></div>
                        </div>
                    </>                            
                }
            </section>
        )
    },[order, props, tax_rate]);

    const handleCollapse=()=>{
        if(collapseState === "open") setCollapseState("closed");
        else setCollapseState("open")
    }

    // useEffect(() => {
    //     setContentPagePart(<>

    //         {/* Headers */}
    //         <section className="preview-headers">

    //             {orderStatusPagePart}

    //             {currentOrder}

    //             {!props.cart_layout && currentCustomer}

    //             {!props.cart_layout && orderDiscountsPagePart }

    //             {order_imported===true && transaction && 
    //                 <div>
    //                     <span className="bold">Transaction # {transaction}</span><br/>
    //                 </div>
    //             }
    //         </section>


    //         {itemsGridPagePart}
            
    //         {footerPagePart}
    //     </>);
    // },[props, aitems, currentCustomer, transaction, allItemsPagePart, orderDiscountsPagePart, footerPagePart, orderStatusPagePart, currentOrder, order_imported, itemsGridPagePart]);

    return (
        <div className={`preview-container ${additionalClass} ${props.cart_layout ? 'patron-cart-preview' : `no-select-text ${collapseState === "closed" ? "collapse-closed" : ""}`}`}>
            {showPayment &&
                <Checkout 
                    register_id={register} 
                    show={showPayment} 
                    onClose={paymentModalCloseHandler} 
                    terminal_device_id={props.terminal_device_id} 
                    is_patron={props.patron_layout}
                />
            }

            {/*
            <Modal show={showPayment} keyboard={false} size="xl" backdrop="static" onHide={paymentModalCloseHandler}>
                <Modal.Header />
                <Modal.Body>
                    <Payment register_id={register} key="tab-checkout" copies={props.copies || "1"} onClose={paymentModalCloseHandler} terminalDeviceId={props.terminal_device_id} patron={props.patron_layout} user_id={props.patron_layout ? "" : null} />
                </Modal.Body>
            </Modal>
            */}

            <span
                className={`collapse-btn ${collapseState}`}
                onClick={handleCollapse}
            >
                {collapseState==="open" ? 
                    <i className="fas fa-chevron-right" />
                    :
                    <i className="fas fa-chevron-left" />
                }
            </span>

            <CouponPicker 
                register_id={register}
                show={couponModalShow} 
                hide={handleCouponClose}
                title="Available Discounts"
            />

            {/* Headers */}
            <section className="preview-headers">

                {orderStatusPagePart}

                {currentOrder}

                {!props.cart_layout && currentCustomer}

                {!props.cart_layout && orderDiscountsPagePart }

                {order_imported===true && transaction && 
                    <div>
                        <span className="bold">Transaction # {transaction}</span><br/>
                    </div>
                }
            </section>


            {itemsGridPagePart}
            
            {footerPagePart}

            {show_checkout_btn &&
                <Button id={`pos-button-checkout`}
                    onClick={(e)=>{
                        if (!order || aitems.length <=0 /*|| !customer*/) return;
                        setShowPayment(true);
                    }}
                    disabled={(order_imported===true || +order_status!==1)}
                    data-cy={`pos-button-checkout`}
                >
                    Checkout
                </Button>
            }
        </div>
    );
}
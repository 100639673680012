import * as actionTypes from '../actions/actionTypes';

const defaultRegister = { 
    registerInfo: null,     // the info from Register.get
    items: [],              // items in cart
    category: null,         // current category
    type: null,             // current type
    item: [],               // selected item
    tab: null,              // current tab
    transaction: null,      // transaction number
    order: null,            // order number
    orderAll: null,         // order object from Order.get
    order_status: 1,        // order status
    order_imported: false,  // false = brand new order, true = old order
    option: "",             // current selected option
    user: null,             // current user
    trigger_picker:null,    // trigger addon picker (2) or variant picker (1)
    coupons_selected: [],   // list of coupon ids
    coupon_codes: [],       // list of coupon_code strings
    updateTrigger: -1,
    tip: 0,
    pre_items: [],              // item list before adding to cart - Pay/PaymentMethod
    new_items: [],           // triggers adding this new item to the cart - OutstandingCharges.js
    requires_membership: [],
    show_event_register: false,
    cart_animation: 0,
    available_tokens: [],
    finished_loading_all: false,
    finished_loading_customer: false,
    updated: false,
    register_group: null,
    tax_rate: null,
};

const initialState = {
    register: null,
    adminfee_rate: 4,
}

const posReducer = (state = initialState, action) => {

    if (action.register_id || action.register){
        const pid=action.register_id || action.register;
        if (!state?.[pid]) state[pid] = {...defaultRegister};
    }

    let found=0;
    let prevState = action.register_id ? [...state[action.register_id].items] : [];
    switch (action.type) {

        case actionTypes.POS_SET_UPDATED:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    updated: action.updated
                }
            }
        case actionTypes.POS_INIT_REGISTER:
            return {
                ...state,
                [action.register]: {...defaultRegister}
            };
        case actionTypes.POS_ACTIVE_REGISTER:
            if (!state[action.register]) state[action.register] = {...defaultRegister};

            return {
                ...state,
                register: action.register,
            };
        case actionTypes.POS_RESET_ITEMS:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: [],
                }
            }
        case actionTypes.POS_ADD_ITEM:
            // checks if the product is already in the list, if it is, updates qty
            found=0;
            prevState.forEach(item=>{
                if ((+item.id===+action.items.id && item.hash===action.items.hash && +action.items.type===2)){ // only updates qty if the product has no variants or if its an addon
                    item.qty++;
                    found=1;
                }
            });
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: state[action.register_id].items===null ? [] : found===0 ? [...state[action.register_id].items, action.items] : [...prevState],
                }
            }
        case actionTypes.POS_ADD_ITEMS_MANY:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: state[action.register_id].items===null ? [] : [...state[action.register_id].items, ...action.items],
                }
            }
        case actionTypes.POS_REMOVE_ITEM:
            // filters out all items with the same hash (hashes are used to group products > variants > addons)
            prevState=[...state[+action.register_id].items].filter(item=>{
                if (item.addons){
                    item.addons=[...item.addons].filter(addon=>{
                        if (+addon.id===+action.items.id && (+addon?.variant_id || 0 === +action.items.variant || 0) && addon.hash===action.items.hash) return null;
                        return addon
                    });
                }
                
                if (+item.id===+action.items.id && (+item?.variant_id || 0 ===+action.items.variant || 0) && +action.items.type===2 && item.hash===action.items.hash) return null; // took out items.type===2 so any item type can be removed
                if (item.hash===action.items.hash && +action.items.type!==2) return null;

                return item;
            });

            return {
                ...state,
                [+action.register_id]: {
                    ...state[+action.register_id],
                    items: [...prevState],
                }
            }
        case actionTypes.POS_ACTIVE_ITEM:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    item: action.item
                }
            };
        case actionTypes.POS_ACTIVE_CATEGORY:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    category: action.category,
                }
            };
        case actionTypes.POS_ACTIVE_TYPE:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    type: action.product_type,
                }
            };
        case actionTypes.POS_TRIGGER_PICKER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    trigger_picker: action.picker,
                }
            };
        case actionTypes.POS_ACTIVE_TAB:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    tab: action.tab,
                }
            };
        case actionTypes.POS_ACTIVE_OPTION:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    option: action.option,
                }
            };
        case actionTypes.POS_UPDATE_QTY:
            prevState.forEach(item=>{
                if (+item.id===+action.item.id && item.hash===action.item.hash){
                    item.qty=action.qty;
                }
            });
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: [...prevState],
                }
            }
        case actionTypes.POS_RESET:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: [],
                    item: null,
                    category: null,
                    user: null,
                    coupon_codes: [],
                    order: null,
                    orderAll: null,
                    transaction: null,
                    // order_imported: false,
                    order_status: 1,
                    requires_membership: [],
                    tip: 0,
                    coupons_selected: [],
                }
            }
        case actionTypes.POS_SET_USER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    user: action.user,
                }
            }
        case actionTypes.POS_TRANSACTION:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    transaction: action.transaction,
                }
            }
        case actionTypes.POS_ORDER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    order: action.order_id,
                }
            }
        case actionTypes.POS_ORDER_ALL:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    orderAll: action.order,
                }
            }
        case actionTypes.POS_ORDER_STATUS:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    order_status: action.order_status,
                }
            }
        case actionTypes.POS_ORDER_IMPORTED:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    order_imported: action.order_imported,
                }
            }
        case actionTypes.POS_COUPONS_SELECTED:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    coupons_selected: action.coupons_selected,
                }
            }
        case actionTypes.POS_UPDATE_TRIGGER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    updateTrigger: action.updateTrigger,
                }
            }
        case actionTypes.POS_COUPON_CODES:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    coupon_codes: action.coupon_codes,
                }
            }
        case actionTypes.POS_TIP:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    orderAll:{...state[action.register_id].orderAll, tip: action.tip},
                    tip: action.tip,
                }
            }
        case actionTypes.RESET_PRE_CART_ITEMS:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    pre_items: []
                }
            }
        case actionTypes.SET_PRE_CART_ITEM:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    pre_items: [...state.pre_items, action.pre_items]
                }
            }
        case actionTypes.POS_SET_NEW_ITEMS:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    new_items: action.new_items
                }
            }
        case actionTypes.POS_SET_CART_ITEMS:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    items: action.items,
                    orderAll: action.orderAll
                }
            }
        case actionTypes.POS_SET_SHOW_EVENT_REGISTER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    show_event_register: action.show
                }
            }
        case actionTypes.POS_SET_CART_ANIMATION:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    cart_animation: action.play
                }
            }
        case actionTypes.POS_SET_AVAILABLE_TOKENS:
            return {
                ...state,
                available_tokens: action.tokens
            }
        case actionTypes.POS_SET_REGISTER_INFO:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    registerInfo: action.registerInfo
                }
            }
        case actionTypes.POS_SET_FINISHED_LOADING_ALL:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    finished_loading_all: action.finished_loading_all
                }
            }
        case actionTypes.POS_SET_FINISHED_LOADING_CUSTOMER:
            return {
                ...state,
                [action.register_id]: {
                    ...state[action.register_id],
                    finished_loading_customer: action.finished_loading_customer
                }
            }
        case actionTypes.POS_SET_REGISTER_GROUP:
            return{
                ...state,
                [action.register_id]:{
                    ...state[action.register_id],
                    register_group: action.register_group
                }
            }
        case actionTypes.POS_SET_TAX_RATE:
            return{
                ...state,
                [action.register_id]:{
                    ...state[action.register_id],
                    tax_rate: action.tax_rate
                }
            }
        default:
            return state;
    }
}

export default posReducer;
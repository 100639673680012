import React, { useEffect, useState } from 'react';
import { Modal, Alert, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

const PopUpAlert = ({
	propShow,
	goto,
	type,
	custom,
	propTitle,
	msg="",
	backdrop, 
	dismissable = true,
	hiddenButton,
	...props}) => {

	let history = useHistory();

	const [show, setShow] = useState(false);
	const [message, setMessage] = useState("");
	const [variant, setVariant] = useState("");
	const [linkOnClose, setLinkOnClose] = useState("");
	const [title, setTitle] = useState("");
	const [closed, setClosed] = useState(false);

	useEffect(() => {
		setShow(propShow);
		setLinkOnClose(goto);
		switch(type) {
			case "error":
				setVariant("danger");
				setTitle("Oops!")
				break;
			case "success":
				setVariant("success");
				setTitle("Success!")
				break;
			case "warning":
				setVariant("warning");
				setTitle("Oops.")
				break;
			case "custom": 
				setVariant(custom || "primary")
				setTitle(propTitle);
				break;
			default:
				setVariant("primary");
				setTitle("Alert");
				break;
		}
		props.children ? setMessage(props.children) : setMessage(msg);
	},[propShow, goto, type, custom, propTitle, msg, props]);

	const closeAlert = () => {
		if(linkOnClose?.length > 0) history.push(linkOnClose);
		setShow(false);
		setClosed(true);
	}

	return (
		<Modal centered show={show && !closed} onHide={closeAlert} backdrop={backdrop || true}>
			<Alert variant={variant} dismissible={dismissable} onClose={closeAlert}>
				<div>
					<h1>{title}</h1>
					<div>{message}</div>
				</div>
				{!hiddenButton && <Button variant={variant} onClick={closeAlert} data-cy="pop-up-button">OK</Button>}
			</Alert>
		</Modal>
	);
};

export default PopUpAlert;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AsyncTypeahead, Typeahead as BootstrapTypeahead, Token } from 'react-bootstrap-typeahead';

import ErrorCatcher from '../common/ErrorCatcher';
import { useDispatch } from 'react-redux';

import { saveLogging } from '../../utils/thunks';
import ServicesAPI from '../../api/Services';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Typeahead.scss';

const PER_PAGE_DEFAULT = 20;

/**Basic async typeahead for searching users.  After a selection is made, prop function will pass the data back up to the parent component.
 * async is not reccomended if loading by dataIds - a limited page response may not encapsulate the loaded data
 * @param {{}} formatForLabel-function
 * @param {{}} makeRequest-function what is talking to the api, can take (query, perPage, page(if paginated)) 
 * @param {()} multiple-boolean to allow multiple selections
 * @param {()} passSelection-function to pass the array of selected Services back - because this is a dependecy, it has to be passed in as a callback to not cause a loop
 * @param {{}} id-string for the async typeahead
 * @param {{}} placeholder-string default to "Enter your search query..."
 * @param {{}} paginated-boolean
 * @param {{}} async-boolean
 * @param {{}} overrideFirstLoad-boolean this will force the "firstLoad" of the typeahead to refresh and thereby import data again
 * @param {{}} parentDoneLoading-boolean will force the typeahead to wait for the parent to finish loading for anything other than the initial request
 * @param {{}} initialData-object takes precedence over initialDataIds - keep in mind, the data you want the typeahead to spit back out after needs to be present in the incoming data, otherwise use !async and dataIds
 * @param {{}} initialDataIds-arrayOfInts - only works initalData=null and !async
*/
export const Typeahead = ({
    formatForLabel = (option) => (`${option?.name}`),
    makeRequest,
    multiple=false,
    passSelection=()=>{},
    id="search",
    placeholder="Enter your search query...",
    perPage=PER_PAGE_DEFAULT,
    paginated=true,
    async=true,
    overrideFirstLoad=null,
    setOverrideFirstLoad=()=>{},
    parentDoneLoading=true,
    initialDataOdd=null,     // objects that don't follow standard ID convention - must include a label, in case they need a special case, filter, what to filter by, and data - currently only compatible with one object
    initialData=null,       // array of objects
    initialDataIds=null,    // array of ids
    tokenClick=()=>{}       // optional - can pass an onClick event (with the clicked option) for when a token (below the multiselect) is clicked
}) => {

    const ref = React.createRef();
    const mountedRef = useRef(false);
    const dispatch = useDispatch();

    const [error, setError] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedResult, setSelectedResult] = useState([]);
    const [cachedResult, setCachedResult] = useState([]);
    const [cachedPage, setCachedPage] = useState(1);
    const [totalRecordCount, setTotalRecordCount] = useState(0);
    const [cachedQuery, setCachedQuery] = useState("");
    const [hasFocus, setHasFocus] = useState(false);
    const [searchInput, setSearchInput] = useState();
    const [firstLoad, setFirstLoad]=useState(true);

    //load the data initially.  NO DEPENDENCIES HERE-FIRST LOAD ONLY
    useEffect(() => {
        mountedRef.current = true;

        //make one initial request from the beginning to have data
        makeRequest("", perPage).then((response) => {
            if (response && mountedRef.current) {
                if (response.errors) {
                    // make this error quiet - send to logging instead of stopping everything on the page
                    dispatch(saveLogging("Error in Typeahead api call: " + response.errors));
                    setError("Error loading");
                } else if (response.data && mountedRef.current) {
                    // replace the cached response
                    setCachedResult(response.data);
                }
            }
            //if we want the typeahead to wait for the parent to finish loading 
            //by default is true and will not hinder anything
            //if there are initialDataIds or initialData, we want to give them a chance to load before removing the loading state
            if(parentDoneLoading && !initialData && !initialDataIds) setLoading(false);
        });

        return () => mountedRef.current = false;
    //we only want it running once so we can avoid the rerunning and rerunning of "make request" when parents and other components are loading
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    //Ensure that if we have initialData or initialDataIds that we have at least one load done(cachedResult check)
    //And that "firstLoad hasn't triggered".  This is the only place to trigger first load to false so it's always used to check and adjust the imported data
    useEffect(()=>{
        if(firstLoad && (initialData || initialDataIds || initialDataOdd) && cachedResult?.length > 0 && mountedRef.current){
            let filteredItems;
            if(initialData) {
                setSelectedResult(initialData);
                passSelection(initialData); 
                //yes, the parent has this data, but not from the typeahead, which most components are looking for when editing a product
            }
            else if(initialDataIds && !async){
                filteredItems=cachedResult.filter((item)=>initialDataIds.includes(item.id))
                setSelectedResult(filteredItems);
                passSelection(filteredItems);
            }
            else if(initialDataOdd && !async){
                if(initialDataOdd.label === "UrlPages") filteredItems = cachedResult.filter(((item)=>item.slug === initialDataOdd.data.index_page))
                else filteredItems = filteredItems = cachedResult.filter((item)=>item.filter === initialDataOdd.filter);
                setSelectedResult(filteredItems);
                passSelection(filteredItems);
            }
            if(filteredItems){
                setLoading(false);
                setFirstLoad(false);
            }  //if there were no filtered items, that means a new item was added after the initial load of data and we need to wait for a toggle of override first load
        }
    },[firstLoad, initialData, initialDataIds, initialDataOdd, cachedResult, async, passSelection]);

    //if the parent needs to refresh the initial data, it can do so by passing in "overrideFirstLoad" and retriggering the above useEffect
    useEffect(()=>{
        if(overrideFirstLoad && mountedRef.current) {
            //get the data again so it includes anything new added
            makeRequest("", perPage).then((response) => {
                if (response && mountedRef.current) {
                    if (response.errors) {
                        // make this error quiet - send to logging instead of stopping everything on the page
                        dispatch(saveLogging("Error in Typeahead api call: " + response.errors));
                        setError("Error loading");
                    } else if (response.data && mountedRef.current) {
                        setOverrideFirstLoad(false);
                        // replace the cached response
                        setCachedResult(response.data);
                        if(initialData){
                            setSelectedResult(initialData);
                            passSelection(initialData);
                        }
                        else if(initialDataIds && !async){
                            let filteredItems=response.data.filter((item)=>initialDataIds.includes(item.id))
                            setSelectedResult(filteredItems);
                            passSelection(filteredItems);
                        }
                        setOverrideFirstLoad(false);
                    }
                }
            });
        }
    },[overrideFirstLoad, setOverrideFirstLoad, makeRequest, perPage, async, initialData, initialDataIds, passSelection, dispatch]);

    //if the parent has said to delay loading and is finsihed
    useEffect(()=>{
        if(parentDoneLoading && mountedRef.current) setLoading(false);
    },[parentDoneLoading]);

    //it seems to be working without this now, but leaving it for future reference, just in case
    // // this is a work-around to fix a bug that the AsyncTypeahead is displaying - it's not resetting when the query is blank
    // const checkToClearInput = useCallback(() => {
    //     let input = document.querySelector(`#${id} input`);
    //     if (input.value==="") {
    //         handleSearch();
    //     }
    // },[cachedResult, id]);

    // this is used because the AsyncTypeahead doesn't do that on its own
    const handleOnFocus = () => {
        setHasFocus(true);
    }

    const handleOnChange = (selectedItems) => {
        setSelectedResult(selectedItems);
        //make sure we have data loaded before we pass anything, 
        //but we don't have to wait for this state before passing it up
        //this state is for the display of the typeahead
        if(cachedResult) passSelection(selectedItems);
        setHasFocus(false);
    }

    const handleSearch = async(query="") => {
        makeRequest(query, perPage).then((response) => {
            if (response && mountedRef.current) {
                if (response.errors) {
                    // make this error quiet - send to logging instead of stopping everything on the page
                    dispatch(saveLogging("Error in Typeahead api call: " + response.errors));
                    setError("Error loading");
                } else if (response.data && mountedRef.current) {
                    // replace the cached response
                    setCachedResult(response.data);
                    setCachedQuery(query);
                }
            }
        });
    }

    const handlePagination = (e, shownResults) => {
        // Don't make another request if:
        // - the cached results exceed the shown results
        // - we've already fetched all possible results
        if (
            cachedResult.length > shownResults ||
            totalRecordCount === cachedResult.length ||
            !mountedRef.current
        ) {
            return;
        }
    
        let page = cachedPage + 1;
        
        makeRequest(cachedQuery, perPage, page).then((response) => {
            if (response) {
                if (!response.errors && response.data) {
                    // add on to the cached response
                    let newResultList = [...cachedResult, ...response.data ];
                    setCachedPage(page);
                    setCachedResult(newResultList);
                }
                if (response.errors) {
                    setError(<ErrorCatcher error={response.errors} />)
                }
            }
        });
    }

    const renderInput = useCallback(({ inputClassName, inputRef, referenceElementRef, ...props },{ onRemove, selected }) => (
        <>
            <input
                {...props}
                className="form-control"
                ref={input => {
                    referenceElementRef(input);
                    inputRef(input);
                }}
                type="text"
            />
            {!props.hideSelected && 
                <div className="tokens-list">
                    {selectedResult?.map((option, i) => (
                        option &&
                        <Token key={`tkn-${i}`} onClick={()=>tokenClick(option)} onRemove={() => onRemove(option)}
                            option={option}
                        >
                            {formatForLabel(option)}
                        </Token>
                    ))}
                </div>
            }
        </>
    ),[selectedResult, formatForLabel, tokenClick]);

    // props for pagination for async
    let additionalProps = {};
    if (paginated) {
        additionalProps = {
            paginate: true,
            onPaginate: handlePagination,
            paginationText: "Click here for more",
            onFocus: handleOnFocus,
            open: hasFocus && !!cachedResult,
            onBlur: () => setHasFocus(false),
        }
    }

    return (
        <div className="general-typeahead" data-cy="general-typeahead" id={id}>
            {async && cachedResult &&
                <AsyncTypeahead
                    id={id}
                    isLoading={loading}
                    disabled={loading || error}
                    minLength={0}
                    onSearch={handleSearch}
                    onChange={handleOnChange}
                    labelKey={formatForLabel}
                    options={cachedResult || []}
                    placeholder={error ? error : loading ? "Loading..." : placeholder}
                    selected={selectedResult}
                    ref={ref}
                    multiple={multiple}
                    clearButton={true}
                    renderInput={multiple ? renderInput : null}
                    useCache={false}    // leaving this on caused a display bug, it wasn't updating properly
                    maxResults={paginated ? perPage-1 : perPage}
                    {...additionalProps}
                />
            }
            {!async && cachedResult &&
                <BootstrapTypeahead
                    id={"regular"}
                    disabled={loading || error}
                    labelKey={formatForLabel}
                    onChange={handleOnChange}
                    options={cachedResult || []}
                    placeholder={error ? error : loading ? "Loading..." : placeholder}
                    selected={selectedResult}
                    ref={ref}
                    multiple={multiple}
                    clearButton={true}
                    renderInput={multiple ? renderInput : null}
                    useCache={false}    // leaving this on caused a display bug, it wasn't updating properly
                />
            }
        </div>
    )
}
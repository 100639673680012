import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { differenceInYears } from "date-fns";

import Users from '../../../../api/Users';
import Events from "../../../../api/Events";
import Products from "../../../../api/Products";

import ErrorCatcher from "../../../../components/common/ErrorCatcher";
import PopUpAlert from "../../../../components/common/PopUpAlert";
import CustomSelectField from "../../../../components/common/CustomSelectField";

import { addToCart } from "../../../../utils/thunks";

import "./Children.scss"

export const Children = (props) => {
    
    let dispatch = useDispatch();
    const history = useHistory();

    const eventID = props.event_id;

    const familyMembers=useSelector(state=>state.family.all_family_no_dupes)
    const [localUser, setLocalUser] = useState();
    const [participants, setParticipants] = useState([]);
    const [selectedParticipant, setSelectedParticipant] = useState();
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [customFields, setCustomFields] = useState([]);
    const [productID, setProductID] = useState();
    const [loadingParticipants, setLoadingParticipants]=useState(true);
    
    let disabled;
    if(submitting || loadingParticipants) disabled = true;
    else if(typeof(selectedParticipant.allowed) === "string") disabled = true;
    else disabled = false;

    const successBlock=(
        <div>
            {productID ?
                <div>   
                    <p>
                        This event requires payment to complete registration! 
                        <br />
                        or press "OK" below to continue and checkout later.  You will not be fully registered for this event until you complete payment.
                    </p>
                    <p className="d-flex justify-content-between"> 
                        <Button onClick={()=>history.push("/p/cart")}>Complete Registration</Button> 
                        <Button onClick={()=>history.push("/p/event-register")} variant="light">OK</Button>
                    </p>
                </div>
                :
                <p>
                    Your registration has been submitted. You should recieve your confirmation email shortly! 
                </p>
            }
        </div>
    )

    useEffect(() => {
        let mounted = true;

        if(eventID){
            Events.getSingle({id: eventID})
            .then( response => {
                if(mounted) {
                    setProductID(response.data[0]?.product_id);
                }else if(mounted && response.errors) setError(<ErrorCatcher error={response.errors} />);
            }).catch(e => console.error(e));

        }

        return () => mounted = false;
    },[eventID]);
    
    // form submission
    const submitInfoHandler = async (event) => {

        const form = event.currentTarget;

        event.preventDefault();
        event.stopPropagation();

        setValidated(true);
        setSubmitting(true);

        if (form.checkValidity() === true) {

            setError(null);
            setSuccess(null);

            const formData = new FormData(event.target);
            formData.append("for_user_id", selectedParticipant.id);
            formData.append("by_user_id", localUser.id)
            formData.append("event_id", eventID);
            const formDataObj = Object.fromEntries(formData);

            await Users.eventRegister(formDataObj)
            .then( async response => {
                if (!response.errors) {
                    setSubmitting(false);
                    setValidated(false);

                    if(productID) {
                        await Products.get({id: productID})
                        .then( response => {
                            // TODO: This has no error handling if the call does not work as intended
                            let item = response.data.products[0];
                            dispatch(addToCart([{
                                category_id: item.categories[0]?.id,
                                discounts: 0,
                                hash: null,
                                id: item.id,
                                product_type_id: item.product_type_id,
                                is_taxable: item.is_taxable,
                                original_price: +item.product_variants[0].price,
                                parent_id: null,
                                product_name: item.name,
                                product_price: +item.product_variants[0].price,
                                qty: 1,
                                type: 1,
                                variant_id: item.product_variants[0].id,
                                variant_name: item.product_variants[0].name,
                                event: {
                                    event_id: eventID,
                                    for_user_id: selectedParticipant.id
                                }
                            }]));
                        }).catch(e => console.error(e));
                    }

                    setSuccess(productID ? successBlock : null);
                }
                else if(response.errors) setError(<ErrorCatcher error={response.errors} />);
            }).catch(e => {
                console.error(e);
                setSubmitting(false);
                setError(e);
            });

            setSubmitting(false);
            setValidated(false);
           
        } else setSubmitting(false);
    };

    const selectParticipantHandler = async e => {
        let participant = JSON.parse(e.target.value);
        setSelectedParticipant(participant);
    }

    useEffect(() => {
        let mounted = true;

        if(eventID){
            Events.get_custom_fields({event_id: eventID})
            .then( response => {
                if(mounted && response.data) {
                    setCustomFields(response.data?.map( (item, i) => {
                        if (item.custom_field_type === "select") {
                            return (
                                <Col lg="12" xl="6" key={`custom-field-${i}`}>
                                    <CustomSelectField
                                        name={item.name}
                                        options={item.options}
                                        placeholder_text={item.placeholder_text}
                                        required={item.required}
                                    />
                                </Col>
                            );
                        } else {
                            return (
                                <Col lg="12" xl="6" key={`custom-input-${i}`}>
                                    <Form.Group controlId="school_name">
                                        <Form.Label>
                                            {item.placeholder_text}
                                        </Form.Label>
                                        <Form.Control
                                            type="text"
                                            required={item.required}
                                            name={"custom_" + item.name}
                                            placeholder={item.placeholder_text}
                                            defaultValue={item.default_value || ""} 
                                        />
                                    </Form.Group>
                                </Col>
                            );
                        }
                    }));
                }
            }).catch(e => console.error(e));
        }

        return () => mounted = false;
    },[eventID])

    useEffect(()=>{
        if(participants.length > 0) setLoadingParticipants(false)
    },[participants])

    useEffect(() => {
        let mounted = true;

        let participantsArray = [];
        Users.get({})
        .then( response => {
            if(mounted && response.data) {
                setLocalUser(response.data.profile);
                let allowed = checkAllowedAge(response.data.profile);
                participantsArray.push({
                    key: 1,
                    name: response.data.profile.first_name + " " + response.data.profile.last_name + " (Me)",
                    value: {
                        id: response.data.profile.id,
                        first_name: response.data.profile.first_name,
                        last_name: response.data.profile.last_name,
                        allowed: allowed
                    }
                });
                if(familyMembers){
                    setParticipantArray(familyMembers, participantsArray);
                }
            }else if(mounted && response.error){
                setError(<ErrorCatcher error={response.errors} />);
            }
        }).catch(e => console.error(e));

        return () => mounted = false;
    },[familyMembers]);

    const setParticipantArray=(response, participantsArray)=>{
        response?.forEach( (user, i) => {
            let allowed = checkAllowedAge(user);
            participantsArray.push({
                key: i + 2,
                name: user.first_name + " " + user.last_name,
                value: {
                    id: user.user_id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    allowed: allowed
                },
            })
        });
        setParticipants(participantsArray);
        setSelectedParticipant(participantsArray[0].value);
    }

    const checkAllowedAge =(user)=>{
        const age = differenceInYears(new Date(), new Date(user.dob));
            let allowed;
            if(props.eventAges.min || props.eventAges.max){
                if(props.eventAges.min && props.eventAges.max){
                    allowed = age >= props.eventAges.min && age <= props.eventAges.max ? true : "Participant is not within the age range";
                }else if(props.eventAges.min){
                    allowed = age >= props.eventAges.min ? true : "Participant is below the age limit";
                }else if(props.eventAges.max){
                    allowed = age <= props.eventAges.max ? true : "Participant is above the age limit";
                }else allowed = true;
            }
            return allowed;
    }

    return (
        <div>
            <Form noValidate validated={validated} onSubmit={submitInfoHandler} className="input-form" data-cy="register-event-lower-card">
                <PopUpAlert
                    propShow={success ? true : false}
                    goto={success && !productID ? "/p/" : "/p/event-register"} 
                    type={"custom"}
                    custom={success && !productID ? "success" : "primary"}
                    propTitle={success && !productID ? "Success!" : "Almost Done!"}
                    msg={success}
                    backdrop={success && !productID ? true : "static"}
                    dismissable={false}
                    hiddenButton={success && !productID ? false : true}
                />
                <h5 className="dash-title" data-cy="register-event-title">Register for this Event</h5>
                <label className="form-label">Participant</label>
                <Row>
                    <Col lg="12" data-cy="register-event-participant">
                        {!loadingParticipants ?
                            <Form.Group controlId="participant">
                                {participants && participants?.length > 1 ?
                                    <Form.Control required as="select" className="form-select participant" onChange={selectParticipantHandler} value={JSON.stringify(selectedParticipant) || ""} >
                                        { participants.map( participant => <option key={participant.key} value={JSON.stringify(participant.value)}>{participant.name}</option> ) }
                                    </Form.Control>
                                : 
                                    <span>{selectedParticipant?.first_name + " " + selectedParticipant?.last_name + " (Me)"}</span>}
                            </Form.Group>
                            :
                            <span>Loading Family ...</span>
                        }
                        {typeof(selectedParticipant?.allowed) === "string" &&
                            <span className="error-text">{selectedParticipant.allowed}</span>
                        }
                    </Col>
                </Row>
                {customFields && customFields?.length>0 &&
                    <h5 className="dash-title pt-3">Registration Questions</h5>
                }
                <Row>
                    { customFields /* Custom response fields for forms that have them */}
                </Row>
                <Row className="mt-5">
                    <Col className="p-0 col-auto">
                        <Button variant="light" href={`/p/event-register${props.event_parent_id ? "?event=" + props.event_parent_id : ""}`} className="gray-b-btn">Back</Button>
                    </Col>
                    <Col className="p-0 col-auto">
                        <Button type="submit" disabled={disabled} className={`orange-btn${submitting?" submitting":""}`} data-cy="event-page-register-btn">Register</Button>
                    </Col>
                </Row>
            </Form>
            {typeof error !== 'string' && 
            <>
                {error}
            </>
            }
        </div>
    );
}

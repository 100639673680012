import React, {useState, useEffect, useCallback, useRef} from 'react';
import {ButtonGroup, Button, InputGroup, FormControl} from 'react-bootstrap';

import styles from '../../Tip/Tip.module.scss';

const cashTypes = [1, 5, 10, 20, 50, 100];
const paymentMethodName = "Cash Tendered";

export const Cash = (props) => {
    const {onPaymentUpdate, onPaymentMethodChange} = props;

    const firstLoad = useRef(true);
    const otherRef = useRef();

    const [cashPayment, setCashPayment] = useState(0);
    const [otherAmount, setOtherAmount] = useState(0);

    const clickHandler = useCallback((e, amount) => {
        e.preventDefault();
        e.stopPropagation();

        if (e.nativeEvent.button === 2) { // right click removes the amount
            setCashPayment(prev=>{
                let newAmount = +prev - +amount;
                if (newAmount < 0) newAmount = prev;
                if(newAmount !== "0.00"){
                    otherRef.current.value = newAmount?.toFixed(2);
                    setOtherAmount(newAmount);
                    return newAmount?.toFixed(2);
                }
                else return 0;
            });
        } else { // everything else adds the amount
            if (e.target.name === "other") {
                setOtherAmount(amount);
                setCashPayment(amount);
            } else {
                setOtherAmount(prev => (+prev + +amount));
                setCashPayment(prev => (+prev + +amount));                
            }
        }
    }, []);

    const resetHandler = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        otherRef.current.value = "";
        setCashPayment(0);
        setOtherAmount(0);
    }, []);

    const otherClickHandler = (e) => {
        e.preventDefault();
        if (otherRef.current) {
            otherRef.current.focus();
            otherRef.current.select();
        }
    }

    useEffect(()=>{
        let _payment = 0;
        if (props.payments){
            const payment = props.payments.find(a=>a.payment_method_id === props.payment_method_id);
            if (payment) _payment = +payment.amount.toFixed(2);
        }
        setOtherAmount(_payment.toFixed(2));
        setCashPayment(_payment.toFixed(2));

        /*if (props.totals && _payment === 0){
            _payment = props.totals.estimated_balance.toFixed(2) % 1 > 0 ? Math.ceil(props.totals.estimated_balance) : props.totals.estimated_balance;
        }*/
    }, [props.totals, props.payments, props.payment_method_id]);

    useEffect(() => {
        if (!firstLoad.current){
            onPaymentUpdate(props.payment_method_id, [{payment_method_name: paymentMethodName, amount: cashPayment}]);
        }
    }, [props.payment_method_id, cashPayment, onPaymentUpdate]);    

    useEffect(()=>{
        if (firstLoad.current) {
            onPaymentMethodChange(props.payment_method_id);
        }
    }, [props.payment_method_id, onPaymentMethodChange]);

    useEffect(()=>{
        if (otherRef.current) {
            otherRef.current.focus();
        }
        
        if (firstLoad.current) {
            firstLoad.current = false;
            return;
        }
    }, []);

    return (
        <>
            <ButtonGroup aria-label="Cash Payment" data-cy="cash-payment-button-group">
                {cashTypes.map((a,i)=>(
                    <Button 
                        key={`cash-amount-${i}`} 
                        variant="light" 
                        className={+a === +cashPayment ? "active" : null}
                        disabled={props.submitting} 
                        onClick={e => clickHandler(e, a)}
                        onContextMenu={e => clickHandler(e, a)}
                    >
                        +${a}
                    </Button>
                ))}
            </ButtonGroup>
            <div className={styles.buttons}>
                <Button variant="light" disabled={props.submitting} onClick={otherClickHandler}>Other</Button>
                <InputGroup style={{maxWidth:"350px"}}>
                    <i className="far fa-arrow-right prepend-arrow"/>
                    <InputGroup.Prepend>
                        <InputGroup.Text><small>$</small></InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        ref={otherRef}
                        data-cy="payment-other-input"
                        type="number"
                        name="other"
                        value={otherAmount}
                        min={0}
                        precision={2}
                        disabled={props.submitting}
                        onChange={e=>setOtherAmount(+e.target.value)}
                        onBlur={e=>clickHandler(e, +e.target.value)}
                        onFocus={e=>e.target.select()}
                    />
                    <InputGroup.Append>
                        <Button variant="light" onClick={resetHandler}><i className="far fa-times"/></Button>
                    </InputGroup.Append>
                </InputGroup>
            </div>
        </>
    );
}
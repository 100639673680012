import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ClosedHours } from './ClosedHours';

import styles from './ClosedHours.module.scss';

//this is intended to be used in the pure POS so it would have all the props of the register definition
export const ClosedHoursTrigger=({
    customText="Following are the hours for this register:",
    ...props
})=>{

    const [ open, setOpen ]=useState(false)

    return(
        <>
            {open ?
                <>
                </>
            :
                // <Button onClick={()=>setOpen(true)}>
                //     View Hours
                // </Button>
                <i 
                    onClick={()=>setOpen(true)}
                    className={`far fa-clock ${styles["hours-btn"]}`} 
                />
            }

            <Modal show={open} onHide={()=>{setOpen(false)}}>
                <Modal.Header closeButton>
                    Register Hours
                </Modal.Header>
                <Modal.Body>
                    <ClosedHours 
                        hours={props?.availability}
                        fullPage={false}
                        customText={customText}
                        customTitle={""}
                    />
                </Modal.Body>
            </Modal>

        </>
    )
}

export default ClosedHoursTrigger;
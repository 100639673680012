import React, { useState, useEffect, useCallback, useRef } from 'react';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';

import Users from '../../../../api/Users';
import Groups from '../../../../api/Groups';

import './Family.css';

export const Family = (props) => {

    const mountedRef = useRef(false);
    const [loading,setLoading]=useState(true);
    const [familyMembers, setFamilyMembers]=useState();

    const getFamilyMembers=useCallback(async()=>{
        let members=[]
        for(let i =0; i <props.userFamily.length; i++){
            let familyId=props.userFamily[i].id
            try{
                let response=await Groups.get({id: familyId});
                if(response.data[0].group_members){
                    response?.data[0]?.group_members?.forEach(member=>{
                        if(member.group_member_status_id===2 && member.user_id !==props.user_id){
                            members.push(
                                {
                                    id: member.user_id,
                                    first_name: member.first_name,
                                    last_name: member.last_name,
                                    group_member_role: member.group_member_role_name
                                }
                            )
                        }
                    });
                }
                if(i+1 === props.userFamily.length){
                    if(mountedRef.current){
                        let uniqueMembers=makeUnique(members);
                        setFamilyMembers(uniqueMembers);
                    }
                }
            }catch(ex){
                console.error(ex);
                setFamilyMembers([])
            }
        }
    },[props.userFamily, props.user_id]);

    // first load, get family members from api
    useEffect(() => {
        mountedRef.current = true

        return () => {
            mountedRef.current = false;
            setLoading(false);
        }        
    },[]);

    useEffect(()=>{
        if(props.userFamily && props.userFamily.length > 0 && mountedRef) getFamilyMembers()
        else if(props.userFamily && props.userFamily.length === 0 && mountedRef.current) setFamilyMembers([])
    },[props.userFamily, getFamilyMembers]);

    useEffect(()=>{
        if(familyMembers) setLoading(false);
    },[familyMembers]);


    //duplicates will still appear if they have a different role in different families.  i.e. is a guardian in one and admin in another
    const makeUnique=(array)=>{
        let stringObj=array.map((eachMember)=>JSON.stringify(eachMember));
        let objectSet=new Set(stringObj);
        let uniqueArray = Array.from(objectSet)
        return uniqueArray.map((eachString)=>JSON.parse(eachString))
    }
    
    return (
        <React.Fragment>
            {loading ? 
                <Spinner animation="border" variant="secondary" />
                :
                <>
                    {!familyMembers?.length &&
                        <span>None</span>
                    }
                    {familyMembers && familyMembers?.map((family,i)=>(
                        <Card key={`family-pos-${family.id}`} data-cy={`family-user-${family.id}`} className="info-container detail-card">
                            <div>Name: {family.first_name} {family.last_name}</div>
                            <div>Role: {family.group_member_role}</div>
                        </Card>    
                    ))}
                </>
            }
        </React.Fragment>
    );
}
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authUserHasModuleAccess } from '../../../utils/auth';

const SB_PRIMARY_COLOR = "#662d91";
const SB_SECONDARY_COLOR = "#f7931e"
const FREESCOUT_MODULE_ID = 336;

/**As far as I can tell, the positions available are bl, br */
export const Freescout = ({theme, position="bl"})=>{

    const user = useSelector(state => state?.auth);
    const [ userHasModuleAccess, setUserHasModuleAccess ]=useState(null)
 
    useEffect(()=>{
        const getUserPermission = async()=>{
            try{
                let response = await authUserHasModuleAccess(FREESCOUT_MODULE_ID)
                if(response) setUserHasModuleAccess(response[FREESCOUT_MODULE_ID]);
            }catch(ex){
                console.error(ex)
            }
        }

        if(user?.user) getUserPermission();
        else setUserHasModuleAccess(null)
    //including userHasModuleAccess will just create a circular dependency
    //eslint-disable-next-line
    },[user])


    useEffect(() => {
        const cleanup=()=>{
            const existingScript = document.getElementById("freescout-w");
            //for whatever reason, when deleting all this in some circumstances, it leaves the button behind.  
            //If the chat is open when someone logs out, it lingers as well.
            //We gotta be sure to get rid of it when we don't want the component.
            const lingeringButton = document.getElementById("fsw-btn");
            const lingeringChat = document.getElementById("fsw-iframe");
            if (existingScript) {
                existingScript.remove();
            }
            if(lingeringButton){
                lingeringButton.remove();
            }
            if(lingeringChat){
                lingeringChat.remove();
            }
            delete window.FreeScoutW;
        }

        if(user?.user && userHasModuleAccess){
            
            window.FreeScoutW = {
              s: {
                color: theme.content.variables.find((variable)=>variable.name ==="$primary-color").value || SB_PRIMARY_COLOR,
                position: position,
                locale: "en",
                visitor_name: user ? user?.user?.profile?.username : "Guest" ,
                visitor_email: user ? user?.user?.profile?.email : "",
                visitor_phone: user ? user?.user?.company_id : null,
                // require:["name","email","phone"], //this is the format listed for when we want to require something - have the things you want required in the array
                id: 186797051
              }
            };
        
            // Only inject the script if it hasn't been added already
            if (!document.getElementById("freescout-w")) {
              const script = document.createElement("script");
              script.async = true;
              script.id = "freescout-w";
              script.src = "https://support.siteboss.net/modules/chat/js/widget.js?v=1286";
              
              // Insert the script before the first script tag in the document
              const firstScript = document.getElementsByTagName("script")[0];
              firstScript.parentNode.insertBefore(script, firstScript);
            }
        }else {
            cleanup();
        }


        return () => {
            // Remove the script tag when component unmounts
            cleanup();
        };
    }, [theme, user, position, userHasModuleAccess]); 
    
    return null; 
}

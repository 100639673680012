"use server";
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary';
import { Row,Col,Button,Card } from 'react-bootstrap';

import { reloadAuth, logout, loadCompanyConfig, loadFeaturesAvailable } from './utils/thunks';
import { injectSASSVars } from './utils/cms';
import { injectCollectJS } from './utils/pos';

import Error from './api/Error';
import Themes from './api/Themes';
import Companies from './api/Companies';
import { FileURLContext } from './contexts/FileURLContext';
import { UploadProgressProvider } from './contexts/UploadProgressContext';
import Routes from './components/Routes';
import Header from "./components/common/Header";
import SideBar from "./components/common/SideBar";
import Footer from "./components/common/Footer";
import CheckAppVersion from "./components/CheckAppVersion";
import Freescout from './components/HelpDesk/FreeScout';
import * as actions from './store/actions';

import CMSParser from './containers/Cms/Parser';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/all.min.css';
import './assets/css/scss/pages.scss';

/*
const imageUrl = {
    base: process.env.REACT_APP_S3_BUCKET_URL+"/themes/"+themeId+"/", //theme.themeS3Bucket.replace(/^"(.*)"$/, '$1'),
    get noPic(){ return this.base+'no-user.svg'},
    get noPicBig(){ return this.base+'no-user-big.svg'.replace(/^"(.*)"$/, '$1')},
    get loginBg(){ return this.base+'login-bg.png'.replace(/^"(.*)"$/, '$1')},
    logo: theme.logo.replace(/^"(.*)"$/, '$1'),
    logoNoText: theme.logoNoText.replace(/^"(.*)"$/, '$1'),
    logoHeader: theme.logoHeader.replace(/^"(.*)"$/, '$1'),
    companyName: theme.companyName.replace(/^"(.*)"$/, '$1'),
}
*/

export const App = () => {
	const dispatch = useDispatch();
    const history = useHistory();
	const userInfo = useSelector(state => state.auth);
    const companyConfig = useSelector(state => state.company?.config);
    const featuresAvailable = useSelector(state => state.permissions?.features)
    
    const [routes, setRoutes] = useState();
    const [context, setContext] = useState('');
    const [imageUrl, setImageUrl] = useState();
    const [theme, setTheme] = useState();
    const [themeCss, setThemeCss] = useState([]);
    const [cmsPage, setCmsPage] = useState();
    const [cmsPageProps, setCmsPageProps] = useState({});
    const [cmsPageRender, setCmsPageRender] = useState();
    const [resetThemeTrigger, setResetThemeTrigger] = useState(false);
    
    /*useEffect(()=>{
        console.log(window.location.href)
    });*/

    useEffect(() => {
        if (companyConfig?.default_patron_register) {
            dispatch(actions.activeRegister(companyConfig.default_patron_register));
        }
    },[dispatch, companyConfig]);   

    useEffect(() => {
        const _getCompanyPaymentConfig = async () => {
            try {
                // Use await to make an asynchronous call to get the company's config data
                // The getConfig method presumably returns a Promise
                const response = await Companies.getConfig({ config_type_id: 3 });

                // Check if the response contains any data; exit if not
                if (!response?.data?.length) {
                    console.log("No payment gateway found");
                    return;
                }

                // Use the reduce function to find the configuration with the lowest sort_order value
                // The reduce function compares each item in the array to find the smallest sort_order
                const selectedConfig = response.data.reduce((minConfig, currentConfig) =>
                    currentConfig.sort_order < minConfig.sort_order ? currentConfig : minConfig
                );

                // Destructure the selectedConfig object to get url and secret_key
                const { url, secret_key } = selectedConfig.config;

                // Check if both url and secret_key exist; if so, inject them using injectCollectJS function
                if (url && secret_key) {
                    injectCollectJS(url, secret_key);
                } else {
                    console.log("Incomplete payment gateway configuration");
                }

            } catch (error) {
                // Log any errors that occur while attempting to get the config
                console.error("An error occurred attempting to get the company payment gateway config:", error);
            }
        }


        //the calls that are made before the user is loaded are resulting in 401s.  
        //gotta make sure these go through to make sure the registers are loaded when the page first loads! 
        //401 calls aren't helping any configs load T.T
        if(userInfo){
            _getCompanyPaymentConfig();
        }
        if(userInfo.user && !companyConfig) {
            console.log("company config thunk", companyConfig)
            dispatch(loadCompanyConfig())
        }
    }, [userInfo, dispatch, companyConfig]);    

    useEffect(()=>{
        if(userInfo.user && !featuresAvailable){
            /*console.log(userInfo)
            console.log("feature thunk", featuresAvailable)*/
            dispatch(loadFeaturesAvailable());
        } 
    },[featuresAvailable, dispatch, userInfo])

    useEffect(() => {
        const _getTheme = async (theme) => {
            let res={};
            //if (theme) res.data = [theme];
            //else res=await Themes.get({my:1});
            res=await Themes.get({my:1});
            if (res?.data){
                const company_logo = res.data[0].logo || null;
                dispatch(actions.setCompanyId(res.data[0].company?.id || null));
                dispatch(actions.setCompanyName(res.data[0].company?.name || "Your Company"));
                dispatch(actions.ThemeSetLogo(company_logo));
                dispatch(actions.ThemeSetCSS(res.data[0].css_ids || []));

                document.title = res.data[0].company?.name || "SiteBoss";                
                setTheme(res.data[0]);
                setThemeCss(res.data[0]?.css_ids || []);
                const companyTheme=Themes.parse(res.data[0].content);
                if (companyTheme?.styles){
                    // check if a style with id theme-styles already exists
                    let _style = document.getElementById("theme-styles");
                    if (_style) _style.parentNode.removeChild(_style);
                    const style = document.createElement('style');
                    style.id = "theme-styles";
                    style.innerHTML = companyTheme.styles;
                    document.head.appendChild(style);
                }
                if (companyTheme?.variables){
                    dispatch(actions.ThemeSetVariables(companyTheme.variables));

                    let {logoNoText,logoHeader,logo,backgroundImage,backgroundEvents,primaryColor,secondaryColor,tertiaryColor,backgroundColor,primaryFontFamily,secondaryFontFamily} = injectSASSVars(companyTheme.variables);
                    let link = document.querySelector("link[rel~='icon']");
                    if (!link) {
                      link = document.createElement('link');
                      link.rel = 'icon';
                      document.getElementsByTagName('head')[0].appendChild(link);
                    }
                    link.crossOrigin = 'anonymous';
                    link.href = logoNoText.replace(/^"(.*)"$/, '$1'); // this is what causes the CORS error

                    setImageUrl({
                        base: res.data[0].resource_directory, /*process.env.REACT_APP_S3_BUCKET_URL+"/themes/"+res.data[0].id+"/"*/
                        get noPic(){ return this.base+'no-user.svg'},
                        get noPicBig(){ return this.base+'no-user-big.svg'},
                        get lost(){ return this.base+'lost.svg'},
                        get loginBg(){ return this.base+'login-bg.png'},
                        get logo(){ return logo.replace(/^"(.*)"$/, '$1')},
                        get logoNoText(){ return logoNoText.replace(/^"(.*)"$/, '$1')},
                        get logoHeader(){ return logoHeader.replace(/^"(.*)"$/, '$1')},
                        get eventsBackground(){ return backgroundEvents.replace(/^"(.*)"$/, '$1')},
                        get backgroundImage(){ return backgroundImage.replace(/^"(.*)"$/, '$1')},
                        get companyName(){ return res.data[0].company?.name || "SiteBoss"},
                        get companyId(){ return res.data[0].company?.id || null},
                        get primaryColor(){ return primaryColor.replace(/^"(.*)"$/, '$1')},
                        get secondaryColor(){ return secondaryColor.replace(/^"(.*)"$/, '$1')},
                        get tertiaryColor(){ return tertiaryColor.replace(/^"(.*)"$/, '$1')},
                        get backgroundColor(){ return backgroundColor.replace(/^"(.*)"$/, '$1')},
                        get primaryFontFamily(){ return primaryFontFamily.replace(/^"(.*)"$/, '$1')},
                        get secondaryFontFamily(){ return secondaryFontFamily.replace(/^"(.*)"$/, '$1')},
                    });
                }

                const _formatSlug = (slug) => {
                    slug = slug.replace(/^\/|\/$/g, '');
                    slug = slug.split("/").filter(el => el !== "");
                    return slug;
                }

                // checks if its a cms page or portal page
                let slug = _formatSlug(window.location.pathname);

                if (res.data[0]?.pages?.length>0 && (res.data[0]?.index_page || slug.length>0)){
                    if (slug.length<=0) slug.push(res.data[0].index_page); // if no slug, use the default index page
                    let _match=0;
                    res.data[0].pages.forEach(p=>{

                        if (p.slug){
                            let params={};
                            let tb_slug = _formatSlug(p.slug);

                            if (p.slug==="/") tb_slug=["/"];

                            if (tb_slug.length>0){
                                let match = true;
                                for (let i=0;i<tb_slug.length;i++){
                                    if (tb_slug[i].substr(0,1)==="{") params[tb_slug[i].substr(1,tb_slug[i].length-2)]=slug[i];
                                    else {
                                        if (tb_slug[i]!==slug[i]){
                                            match=false;
                                            break;
                                        }
                                    }
                                }
    
                                if (match) {
                                    _match = p.id;
                                    if (params) setCmsPageProps(params);
                                    setCmsPage(p.id);
                                    return false;
                                }
                            }
                        }
                    });
                    if (!_match) setCmsPage(0);
                } else setCmsPage(0);
            }
        }

        dispatch(reloadAuth());

        let storedTheme = JSON.parse(localStorage.getItem('theme'));
        if (storedTheme && !resetThemeTrigger){
            _getTheme(storedTheme);
        } else {
            _getTheme();
            setResetThemeTrigger(false);
        }
    },[dispatch, resetThemeTrigger]);

    useEffect(()=>{
        if(theme) localStorage.setItem('theme', JSON.stringify(theme))
    },[theme]);

	useEffect(() => {
        if (imageUrl){
            // set company-specific image urls
            setContext(imageUrl);
            /*
            const root = document.documentElement;
            root.style.setProperty('--logo-notext-url', `url(${imageUrl.logoNoText})`);
            root.style.setProperty('--logo-header-url', `url(${imageUrl.logoHeader})`);
            root.style.setProperty('--logo-url', `url(${imageUrl.logo})`);
            root.style.setProperty('--background-url', `url(${imageUrl.loginBg})`);
            */
        }
	}, [dispatch,imageUrl]);

    useEffect(() => {
        //console.log("resetThemeTrigger",resetThemeTrigger);
    },[resetThemeTrigger]);

    useEffect(() => {
        const resetTheme = () => {
            setResetThemeTrigger(true);
        }

        setRoutes(<Routes logged_in={userInfo.logged_in} resetTheme={resetTheme} />);
    },[userInfo]);

    useEffect(() => {
        if (cmsPage>0){
            setCmsPageRender(<CMSParser page_id={cmsPage} {...cmsPageProps} css_ids={themeCss}/>);
        }
    },[cmsPage, cmsPageProps, themeCss]);

    useEffect(() => {
        return () => {
            setRoutes(null);
            setContext('');
            setImageUrl(null);
            setTheme(null);
            setThemeCss([]);
            setCmsPage(null);
            setCmsPageProps({});
            setCmsPageRender(null);
            setResetThemeTrigger(false);
        }
    },[]);


    const handleError = (error, info, userProfile) => {
        let message = userProfile ? `User# ${userProfile.id} ${userProfile.first_name} ${userProfile.last_name}` : "";
        message += "\n" + window.navigator.userAgent;
        message += "\n" + error.message;
        message += info.componentStack;
        Error.save({
            name: "ERROR BOUNDARY",
            message: message
        });
    }

    const resetStateLocalStorage = (resetErrorBoundary) => {
        dispatch(logout(history));
        resetErrorBoundary();
    };

    if (!theme){
        return <div className="loading-screen" />;
    }

	return (
		<ErrorBoundary
            fallbackRender = {({error, resetErrorBoundary, componentStack}) => (
                <div className="error-boundary-wrapper">
				<Card className="error-boundary-fallback">
					<Row>
                        <Col xs={12}>
						    <h1>Something weird just happened...</h1>
						    <p>An error occurred, and has been logged for review by the tech team.</p>
                        </Col>
                    </Row>
                    <Row>
						<Col className="try-home-btns">
							<Button variant="light" href="/p/" >Home</Button>
							<Button variant="light" onClick={(e) => resetStateLocalStorage(resetErrorBoundary)}>Reset</Button>
						</Col>
					</Row>
				</Card>
                </div>
            )}
            onError = {(error, info) => handleError(error, info, userInfo?.user?.profile)}
        >
            <Router>
                <FileURLContext.Provider value={context}>
                {cmsPageRender}
                {cmsPage===0 &&
                    <>
                        {userInfo.logged_in===true && userInfo.user?.profile &&
                            <Header
                                logged_in={userInfo.logged_in} 
                                user={{
                                    first_name:userInfo.user.profile.first_name,
                                    last_name:userInfo.user.profile.last_name,
                                    role_name:userInfo.user.profile.role_name,
                                    picture:userInfo.user.profile.profile_img_path,
                                    cover:userInfo.user.profile.cover_img_path
                                }}
                                theme={theme?.content?.properties?.header || null}
                                company_name={theme.company?.name || "SiteBoss"}
                                cart_register_id={companyConfig?.default_patron_register || null}
                            />
                        }
                        <div className="main">
                            <SideBar
                                logged_in={userInfo.logged_in}
                                userInfo={userInfo}
                            />
                            
                            <UploadProgressProvider>
                                <div className="ms-sm-auto main-content">
                                    {routes}
                                </div>
                            </UploadProgressProvider>
                            
                            <CheckAppVersion />
                            
                        </div>
                        {/* {userInfo.logged_in===true &&
                            <Footer />
                        } */}
                        {/* <Slaask url={window.location.pathname} /> */}
                        <Freescout theme={theme} />
                    </>
                }
                </FileURLContext.Provider>
            </Router>
		</ErrorBoundary>
	);
}

export default App;

import React, {useEffect, useRef, useCallback, useState} from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Col, Row, Container, Form, InputGroup, Button, Table } from 'react-bootstrap';
import { format } from 'date-fns';

import Pagination from '../../../components/common/Pagination';
import OrderPage from '../OrderPage';
import { TableWithDropdown } from '../../../components/common/TableWithDropdown/TableWithDropdown';
import { TableSkeleton } from '../../../components/common/TableSkeleton/TableSkeleton';
import UserOrders from '../../User/Profile/UserOrders';
import OrderStatusesTypeahead from '../../../components/Typeahead/OrderStatusesTypeahead';

import usePrevious, { useRoleCheck } from '../../../components/common/CustomHooks';
import { setErrorCatcher } from '../../../utils/validation';
import { authUserHasModuleAccessMany } from '../../../utils/auth';

import POS from '../../../api/Pos'
import { order } from '../../../store/actions';

//src\containers\Orders\POSOrders\POSOrders.js
//src\containers\Orders\AllOrders\AllOrders.js

const REFUND_PERMISSION_MODULE_ID = 178;
const VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID = 320;
const VIEW_TRANSACTION_STATUS_MODULE_ID = 302;

const ORDER_STATUSES=[
    {id: 2, name: "Completed", shortName: "Comp"},
    {id: 1, name: "Pending", shortName: "Pend"},
    {id: 3, name: "Cancelled", shortName: "Canc"},
    {id: 4, name: "Refunded", shortName: "Ref"},
    {id: 5, name: "Partially Refunded", shortName: "Partial"},
    {id: 8, name: "Ordered", shortName:"Ord"},
    {id: 9, name: "In Process", shortName: "Proc"},
    {id: 10, name: "Ready For Pickup", shortName: "Rdy"},
    {id: 11, name: "Complete Pickup", shortName: "Picked"}
];

export const OrderTable = ({userId = null, ...props}) => {
    const { isMobile, noModal, from, orderLimit, setActiveOrderId, onClose, reset=null } = props;

    const mountedRef=useRef(false);
    const history =useHistory();
    const active_register=useSelector(state => state.pos.register);
    //Once order statuses have an endpoint, this will have to be retrieved dynamically

    const [loading, setLoading]=useState(true);
    const [isAdmin, setIsAdmin]=useState(false);
    const [isStaff, setIsStaff]=useState(false);
    const [canSeeTransStatus, setCanSeeTransStatus]=useState(false);

    const [orders, setOrders]=useState([])
    const [totalItems,setTotalItems]=useState(0);
    const [itemsPerPage,setItemsPerPage]=useState(orderLimit);
    const [page,setPage]=useState(1);
    const [pages, setPages]=useState([])
    const [sortColumn,setSortColumn]=useState("created_at");
    const [sortOrder,setSortOrder]=useState("DESC");
    const [selectedStatus,setSelectedStatus]=useState([2]);
    const [search,setSearch]=useState("");
    const [ activeOrder, setActiveOrder ]=useState(null);
    const [ hideForRefund, setHideForRefund ]=useState(false);
    const [ error, setError ]=useState();
    const oldReset = usePrevious(reset); //to keep track of if the refunds have been finished to "close" the refund screen.  Also allows to be able to do the same for multiple refunds from the order page without interference

//#region useCallbacks    
    //are they admin or staff?
    const checkStaffPermission=useCallback(async()=>{
        try{
            let response = await authUserHasModuleAccessMany([
                REFUND_PERMISSION_MODULE_ID,
                VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID,
                VIEW_TRANSACTION_STATUS_MODULE_ID
            ])
            if(response){
                setIsAdmin(response[REFUND_PERMISSION_MODULE_ID])
                setIsStaff(response[VIEW_ADVANCED_ORDER_DETAILS_MODULE_ID])
                setCanSeeTransStatus(response[VIEW_TRANSACTION_STATUS_MODULE_ID])
            }
            else{ //if there's a problem, assume the user doesn't have access
                setIsAdmin(false);
                setIsStaff(false);
            }
        }catch(ex){
            console.error(ex)
        }

    },[]);

    const setUpPages=useCallback(responseRecords=>{
        const total_pages=Math.ceil(responseRecords/itemsPerPage);
        let tmp_pages=[];
        for(let i=0;i<total_pages;i++){
            tmp_pages.push(i);
        }
        setPages(tmp_pages);
    },[itemsPerPage]);

    const handleOrders=useCallback((orders)=>{
        for(let i = 0; i < orders.length; i++){
            let statusName = ORDER_STATUSES.filter(status=>status.id===orders[i].order_status_id);
            if(isMobile) orders[i].status_name = statusName[0].shortName;
            else orders[i].status_name = statusName[0].name;
        }
        return orders;
    },[isMobile])

    const getOrders=useCallback(async()=>{
        let postParams = {
            method: "POST",
            max_records: itemsPerPage,
            page_no: page,
            sort_col: sortColumn,
            sort_direction: sortOrder,
            order_status_id: selectedStatus,
            last_three: search
        }

        if(userId) postParams.user_id = userId;

        try{
            let response = await POS.order.get(postParams);
            if(mountedRef && (response.errors || response.message==="Server Error")){
                setError(setErrorCatcher(response.error || response.message));
                setLoading(false)
            }else if(response.data && mountedRef.current){
                setTotalItems(response.data.total_record_count);
                let allOrders = handleOrders(response.data.orders);
                setOrders(allOrders)
                setUpPages(response.data.total_record_count);
                setLoading(false);
            }
        }catch(ex){ 
            console.log(ex)
            setLoading(false)
        }
    },[itemsPerPage, page, sortColumn, sortOrder, selectedStatus, search, setUpPages, userId, handleOrders]);


//#region useEffects    
    useEffect(()=>{
        mountedRef.current = true;

        return()=>{
            mountedRef.current = false;
            setOrders([]);
            setActiveOrderId();
        }
    },[setActiveOrderId]);

    useEffect(()=>{
        if(reset && oldReset !==reset) setHideForRefund(false);
    },[reset, oldReset])

    useEffect(()=>{
        if(mountedRef.current){
            getOrders()
        }
    },[getOrders]);

    useEffect(()=>{
        if(hideForRefund === false){
            const checkbox = document.getElementById("status-paid")
            if(selectedStatus==="1") checkbox.checked = true
            setActiveOrderId();
        }
    },[hideForRefund, selectedStatus, setActiveOrderId]);

    useEffect(()=>{
        if(mountedRef.current){
            if(activeOrder) setActiveOrderId(activeOrder.id)
            else setActiveOrderId();
        }
    },[activeOrder,setActiveOrderId])

    useEffect(()=>{
        checkStaffPermission();
    },[checkStaffPermission]);

    const gotoPage=(page)=>{
        if (page>0) setPage(page);
    }

    const headerClick=(column, direction)=>{
        setSortColumn(column);
        setSortOrder(direction);
    };

    const statusClickHandler=(e)=>{
        let tempStatus = [...selectedStatus]
        if(e.target.checked && !tempStatus.includes(+e.target.value)) {
            tempStatus.push(+e.target.value);
        }
        else if(!e.target.checked){
            let index = tempStatus.indexOf(+e.target.value)
            tempStatus.splice(index, 1)
        }
        setSelectedStatus(tempStatus)
    };

    const posStatusHandler=(e)=>{
        if (e.target.checked) setSelectedStatus(e.target.value);
        else setSelectedStatus(2);
    }

    const activeOrderHandler=(row)=>{
        console.log(row)
        setActiveOrder(row);
        if(history.location.pathname.includes("pos")) setHideForRefund(true);
        else history.push(`/p/order/${row.id}`)
    };

    const adjustItemsPerPage=(e)=>{
        if(e.target.value==="") setItemsPerPage(1);
        else if(+e.target.value >99) setItemsPerPage(99)
        else setItemsPerPage(e.target.value)
    };

    const tableHeaders = [
        {key: "created_at", label: "Date", sortHeader: "created_at"},
        {key: "id", label: "Order #", sortHeader: "id"},
        {key: "user_name", label: "Username"},
        {key: "location_name", label: "Location"}, 
        {key: "total_price", label: "Total"}
    ]
    if(!isMobile){
        tableHeaders.push({key: "user_fname", label: "First Name"}) 
        tableHeaders.push({key: "user_lname", label: "Last Name"}) 
    }else{
        tableHeaders.push({key:"multiple-fullName", label: "Name", multiple: ["user_fname", "user_lname"]})
    }if(canSeeTransStatus){
        tableHeaders.push({key: "status_name", label: "Status"})
    }
    
    return (
        <Container fluid>
        {error}
        {hideForRefund ? 
            <OrderPage importedId={activeOrder.id} registerId={active_register} from={from} />
        :
            <>
                <Form>
                    <Row className="d-flex">
                        <Col>
                            <Form.Group className=" mb-0">
                                <Form.Label>Search</Form.Label>
                                <Form.Control
                                    id="search_input"
                                    placeholder={`Search order number or last 3...`}
                                    aria-label={`Search order number or last 3...`}
                                    value={search || ""}
                                    onChange={e =>setSearch(e.target.value)} 
                                    type="number"
                                    />
                            </Form.Group>
                        </Col>
                        <Col className="d-flex align-items-center justify-content-end">
                        {from==="pos-orders" ?
                            <Form.Group controlId="status-paid" className="form-switch">
                                <Form.Check id="status-paid" label="Pending Orders" value="1" onClick={e=>posStatusHandler(e)} />
                            </Form.Group>
                        :
                            <Col>
                                <Form.Group>
                                    <Form.Label>Order Status</Form.Label>
                                    <OrderStatusesTypeahead 
                                        ids={[1,2,3,4,5,8,9,10,11]}
                                        passSelection={(selection)=>setSelectedStatus(selection.map((select)=>select.id))} 
                                        initialDataIds={selectedStatus}
                                    />
                                </Form.Group>
                                {/* <div className="select-checks">
                                    {ORDER_STATUSES.map((status)=>(
                                        <span key={`status-paid-${status.id}`}>
                                            <label htmlFor={`status-paid-${status.id}`}>{status.name}</label>
                                            <input type="checkbox" id={`status-paid-${status.id}`} label={status.name} defaultChecked={selectedStatus.includes(status.id) ? true : false} value={status.id} onClick={e=>statusClickHandler(e)} />
                                        </span>
                                    ))}
                                </div> */}
                            </Col>
                        }
                        
                        </Col>
                        {from==="orders-page" &&
                            <Col>
                                <Form.Group className="mb-0">
                                        <Form.Label>Records Per Page</Form.Label>
                                        <Form.Control
                                            id="per-page-input"
                                            placeholder={`${itemsPerPage} records per page`}
                                            type="numeric"
                                            label="Records Per Page"
                                            onChange={adjustItemsPerPage}
                                        />
                                </Form.Group>
                                
                            </Col>
                        }
                    </Row>
                </Form>
                <Row className="mt-3">
                    <Col sm="12" >
                        <div>
                            <TableWithDropdown 
                                loading={loading}
                                skeletonRows={itemsPerPage}
                                title={null}
                                tableHeadings={tableHeaders}
                                data={orders}
                                // expandedRow = {true}
                                // expandedText={"More Info"}
                                // expandedHeadings={[{key: "tip", label: "Tip"}, {key: "user_id", label: "Date"}, {key: "register_id", label: "Register"}]}
                                ellipsis={false}
                                clickCell={true}
                                clickCellContent={<Button data-cy="order-details-btn">Details</Button>}
                                clickCellFunction={activeOrderHandler}
                                sortOnClick={headerClick}
                                name="orders"
                            />
                        </div>
                        {pages.length>1?
                            <div className="d-flex justify-content-end mt-2">
                                <Pagination
                                    itemsCount={totalItems}
                                    itemsPerPage={itemsPerPage}
                                    currentPage={page}
                                    setCurrentPage={setPage}
                                    alwaysShown={false}
                                    />
                            </div>
                        : null}
                    </Col>
                </Row>
            </>
        }
    </Container>
  )
}

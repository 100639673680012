export const createImage = (url) =>
    new Promise((resolve, reject) => {
        const image = new Image()
        image.src = url;
        image.setAttribute('crossOrigin', 'anonymous')
        image.addEventListener('load', () => resolve(image))
        image.addEventListener('error', (error) => reject(error))
    })

export function rotateSize(width, height) {

    return {
        width: width,
        height: height
    }
}

export default async function getCroppedImg(
    imageSrc,
    pixelCrop,
    ) {
    const image = await createImage(imageSrc)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    if (!ctx) {
        return null
    }

    canvas.width = 450
    canvas.height = 450

    ctx.drawImage(
        image,
        pixelCrop.x,
        pixelCrop.y,
        pixelCrop.width,
        pixelCrop.height,
        0,
        0,
        canvas.width,
        canvas.height
    )

    // MDN Method to local
    // try {
    //     localStorage.setItem("cropped-img", canvas.toDataURL("image/png"));
    //   }
    //   catch(err) {
    //     console.log("Error: " + err);
    //   }

    // As Base64 string
    return canvas.toDataURL('image/webp');

    // As a blob
    // return new Promise((resolve, reject) => {
    //     canvas.toBlob((file) => {
    //         image.src = resolve(URL.createObjectURL(file))
    //     }, 'image/jpeg')
        
    // })
}

import React, {useState, useEffect, useRef, useCallback} from 'react';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import { format, startOfToday, endOfToday } from 'date-fns';
import { Button, Spinner, Row, Col, Form } from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import Pagination from '../../../components/common/Pagination';
import * as actions from '../../../store/actions'
import Pos from '../../../api/Pos'
import Users from '../../../api/Users';
import Registers from '../../../api/Registers';
import './OpenOrders.scss'


//add redux to say that order is resumed, cancel the update trigger on POS line 65 by using it as a conditional

/** props to be passed in are as follows
 * @param {int} user_id //if there's a user id, it will check for just user orders, regardless of register.  Register Id is still required though
 * @param {int} register_id //if there's a register id without user id, it will search by register group
 * @param {func} closeHandler //whatever needs to happen/trigger when everything is said and done
 */
export const OpenOrders = ({user_id, register_id, ...props}) => {

    const dispatch = useDispatch();

    const company=useSelector(state=>state.company);    
    const GUEST_USER_ID = company?.config?.guest_user_id || null;

    const mountedRef = useRef(false);
    const [loading, setLoading]=useState(true);
    const [loadingDetails, setLoadingDetails] = useState(true);
    const [error, setError]=useState(null);
    const [registerArray, setRegisterArray]=useState([]);
    const [openOrders, setOpenOrders]=useState([]);
    const [activeOrder, setActiveOrder]=useState(null);
    const [customer, setCustomer]=useState(null);
    const [fullOrder, setFullOrder]=useState(null);
    const [pageNumber, setPageNumber]=useState(1);
    const [buttonDisable, setButtonDisable]=useState(true);
    const [totalRecords, setTotalRecords]=useState(0);
    const [footNote, setFootNote]=useState();

    //getOrders
    const getOrders = useCallback(async () => {
        if(registerArray?.length > 0 || user_id){
            let searchFilters={
                method: "POST",
                order_status_id: "1",
                page_no: pageNumber,
                max_records: 7,
                sort_direction: "DESC",
            }
            if(user_id) searchFilters.user_id = user_id
            else if(registerArray.length > 0) {
                searchFilters.register_id = registerArray;
                searchFilters.start_datetime = startOfToday(new Date());
                searchFilters.end_datetime = endOfToday(new Date());
            }
            try{
                let response = await Pos.order.get(searchFilters);
                if(!response.errors && mountedRef.current){
                    setOpenOrders(response.data.orders)
                    setTotalRecords(response.data.total_record_count)
                }else if(response.errors){
                    console.error(response.errors)
                    setError("There was a problem retrieving the orders.")
                }
            }catch(ex) {console.error(ex)}
            setLoading(false);
        }
    },[registerArray, user_id, pageNumber]);

    //first load
    useEffect(()=>{
        mountedRef.current = true

        // let previousOrder=JSON.parse(localStorage.getItem(`POS-${register_id}-order-id`))
        // props.setPreviousOrderId(previousOrder.id)

        return () => {
            mountedRef.current = false
            setCustomer(null);
            setFullOrder(null);
            setActiveOrder(null);
            setPageNumber(1);
            setError(null);
            setButtonDisable(true);
        }
    },[])

    useEffect(()=>{
        getOrders();
    },[pageNumber, registerArray, getOrders]);

    useEffect(()=>{
        if(fullOrder !== null && (customer !== null || fullOrder.user_id === GUEST_USER_ID)){
            if(mountedRef.current) setButtonDisable(false);
        }
        if(fullOrder && mountedRef.current && fullOrder.split_order_ids.length>0){
            setButtonDisable(true);
            setFootNote("This order was previously split and cannot be resumed.")
        } else setFootNote();
    },[fullOrder, customer, GUEST_USER_ID])

    useEffect(()=>{

        const getRegisterGroup=async()=>{
            try{
                let response = await Registers.Groups.get({register_id: register_id})
                //we have to check for 200 first because we can have success and have no register group, so gotta check seperately
                if(response.status ===200){
                    if(response.data[0].registers.length) setRegisterArray(response.data[0].registers.map((each)=>each.id))
                    else setRegisterArray([register_id]) 
                }else if(register_id && (response.errors || response.status !== 200)) setRegisterArray([register_id]);
            }catch(ex){console.error(ex)}
        }

        if(register_id && mountedRef.current){
            getRegisterGroup();
        }

    },[register_id])

    useEffect(()=>{
        if(!activeOrder && mountedRef.current){
            setFullOrder(null);
            setCustomer(null);
            setError(null);
        }
    },[activeOrder])

    const activeOrderHandler=(order)=>{
        setLoadingDetails(true);
        if(!activeOrder || activeOrder.id !== order.id) {
            setActiveOrder(order);
            getSingleOrder(order);
            if( order.user_id !== GUEST_USER_ID )getUser(order);
            else setCustomer(null);
        }
        if(activeOrder && activeOrder.id === order.id) {
            setActiveOrder(null)
            setFullOrder(null);
        };
    }

    const getSingleOrder = async(order)=>{
        if(order){
            try{
                let response = await Pos.order.get({id: order.id});
                if (!response.errors) {
                    setFullOrder(response.data);
                    setLoadingDetails(false);
                } else if(response.errors) {
                    console.error(response.errors);
                    setError("There was a problem getting the selected order.");
                }
            }catch(ex){console.error(ex)}
        }
    };

    const getUser=async(order)=>{
        try{
            let response = await Users.get({id: order.user_id})
            if(!response.errors){
                setCustomer(response.data[0])
            }else if(response.errors){
                console.error(response.errors)
                setError("There was a problem retrieving the order.")
            }
        }catch(ex){console.error(ex)}
    }

    const resumeOrder = async () => {
        if(fullOrder && (customer || (fullOrder.user_id ===7 && !customer))){
            try{
                await Pos.local.save(`POS-${register_id}-order-id`, fullOrder.id);
                dispatch(actions.orderImported(true, register_id));
                props.closeHandler();
            }catch(ex) {console.error(ex)}
        }
    };

    if(loading) return(
        <div className="m-5">
            <SkeletonTheme color="#e0e0e0">
                <Skeleton height={12} count={5} />
            </SkeletonTheme>
        </div>
    )

  return (
    <>
        <Row className="pos-open-order-wrapper">
            {error &&
                <Col>
                    {error}
                </Col>
            }
            <Col sm="auto" className={`${activeOrder && 'active-order'}`}>
                <div className="wizard">
                    {!openOrders.length>0 && 
                        <>
                            <h4>Whoa!</h4>
                            <span>No open orders yet.</span>
                        </>
                    }
                    {openOrders?.map((order, index) => (
                        <>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <Form.Check 
                                    key={`each-order-${order.id}`}
                                    type="radio"
                                    id={`each-order-${order.id}`}
                                    onClick={()=>activeOrderHandler(order)}
                                    label={
                                        <>
                                            <div className="d-flex flex-column">
                                                <span className="bold">Order #{order.id}</span>
                                                <span>
                                                    {format(new Date(order?.updated_at), "HH:mm a" )}
                                                    {" "}
                                                    {order?.user_fname.substring(0, 7)}{order?.user_fname?.length>7 && <span>...</span>}
                                                    {" "}
                                                    {order?.user_lname.substring(0, 7)}{order?.user_lname?.length>7 && <span>...</span>}
                                                </span>     
                                            </div>
                                        </>
                                    }
                                    checked={activeOrder?.id === order?.id}
                                    className="form-radio"
                                />
                                {activeOrder?.id === order?.id &&
                                    <i className="far fa-arrow-right ms-2"></i>
                                }
                            </div>
                        </>
                    ))}
                </div>
                {openOrders.length>0 && 
                    <Pagination
                        itemsCount={totalRecords}
                        itemsPerPage={7}
                        currentPage={pageNumber}
                        setCurrentPage={setPageNumber}
                        alwaysShown={true}
                    />
                }
            </Col>
            <Col className={`right-side ${fullOrder && 'active-order'}`}>
                {loadingDetails && fullOrder &&
                    <div className="mt-4 mr-4 horizontal-align-center">
                        <Spinner animation="border" variant="secondary" />
                    </div>
                }
                {!loadingDetails && fullOrder &&
                    <Row>
                        <Col sm="12" md="auto">
                            <Form.Label>Order #</Form.Label><br/>
                            {fullOrder?.id}
                        </Col>
                        <Col sm="12" md="auto">
                            <Form.Label>Time</Form.Label><br/>
                            {format(new Date(fullOrder?.updated_at), "HH:mm a" )}
                        </Col>
                        <Col sm="12" md="auto">
                            <Form.Label>For User</Form.Label><br/>
                            {fullOrder?.user_fname} {fullOrder?.user_lname}
                        </Col>
                        <Col sm="12">
                            <Form.Label>Items</Form.Label><br/>
                            <div className="item-grid">
                                {fullOrder?.items.length > 0 ?
                                    <>
                                        {fullOrder?.items.map((item)=>(
                                            <React.Fragment key={`each-item-${item.id}`}>
                                                <span className="ms-2">${item.final_price}</span>
                                                <span>{item?.product_name.substring(0, 30)} {item?.product_name.length > 30 && "..."}</span>
                                            </React.Fragment>
                                        ))}
                                    </>
                                    :
                                    <span className="tabbed-span">
                                        No items in this order.
                                    </span>
                                }
                            </div>
                        </Col>
                        <Col sm="12" className="tabbed-span mt-3">
                            <Button variant="primary" disabled={buttonDisable} onClick={resumeOrder}>
                                Resume Order
                            </Button>
                            <br/>
                            {footNote && <span className="footnote">{footNote}</span>}
                        </Col>
                    </Row>
                }
            </Col>
        </Row>
    </>
  )
}

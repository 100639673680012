import React, { useEffect, useState, useCallback,useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { endOfDay, startOfDay } from 'date-fns';

import * as actions from '../../../store/actions';
import * as thunks from '../../../utils/thunks/notifications';
import Pos from '../../../api/Pos';
import styles from './NotificationPreview.module.scss';
import { PopoverTrigger } from '../../common/PopoverTrigger/PopoverTrigger';

const ORDER_INTERVAL = 120000;


/** @param {{}} pickupPoll - if orders is true, will poll for any orders entering the "ready for pickup" status 
 * 
 * In the future, we'll have to add notification checks for other types of notifications.
 * 
 * The flashing color (active-icon) is for when a new notification comes in
 * The red dot is for any individual notifications being marked as not read
*/
export const NotificationPreview = ({pickupPoll=true})=>{

    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user.profile.id)
    // const orderNotifications = useSelector(state => state.notifications.orders || []);

    // const [newUnseen, setNewUnseen] = useState(thunks.readUnseenLocalStorage());
    const [orderNotifications, setOrderNotifications]=useState([])
    const [newUnseen, setNewUnseen] = useState(false);
    const [fullMessageId, setFullMessageId]=useState({})
    // const [hasAnyUnread, setHasAnyUnread]=useState(thunks.checkAllUnread || true);
    const [hasAnyUnread, setHasAnyUnread]=useState(true);
    
    const checkLocal =useCallback(()=>{
        const notis = localStorage.getItem("notifications");
        setOrderNotifications(JSON.parse(notis).orders || []);
    },[])

    const checkAnyUnread=useCallback(()=>{
        let hasAny = thunks.checkForUnread("orders");
        setHasAnyUnread(hasAny)
    },[])

    const checkPickupOrders=useCallback(async()=>{
        try{
            let response = await Pos.order.get({
                method: "POST",
                order_status_id: [8, 9, 10],
                user_id: user,
                date_search_by: "updated_at",
                start_datetime: startOfDay(new Date()),
                end_datetime: endOfDay(new Date())
            })
            if(response.status===200 && response.data.orders){
                let notifications = []
                response.data.orders.forEach((pickup)=>{
                    let noti={
                        id: pickup.id,
                        read: false,
                        subject: `${pickup.id} is ready to be picked up!`,
                        message:  `Your order, ${pickup.id} is ready to be picked up! You can find more details at the order page:`,
                        link: `/p/order/${pickup.id}`,
                        linkMessage: "Order Page",
                        order_status_id: pickup.order_status_id, 
                        order: pickup
                    }
                    notifications.push(noti)
                })
                thunks.addNewToExistingNotificationsByType("orders", notifications);
                thunks.removeByKeyValuePair("orders", "order_status_id", 10, false);
                checkLocal();
                checkAnyUnread();
                // dispatch(thunks.handleAllNotifications("orders", notifications, null, orderNotifications ? orderNotifications : null))
            }
        }catch(ex) {
            console.error(ex)
        }
    //order notifications is just here to follow a little different procedure.  Does not need to be here  as it causes a loop
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[user])

    useEffect(()=>{
        let interval;

        if(pickupPoll){
            if(!interval) checkPickupOrders();

            interval = setInterval(()=>{
                checkPickupOrders();
            }, ORDER_INTERVAL)
        }

        return()=>{
            clearInterval(interval);
        }
    //eslint-disable-next-line react-hooks/exhaustive-deps
    },[pickupPoll, checkPickupOrders]);

    const handleNotificationClick=()=>{
        // thunks.handleUnseenLocalStorage(false)
        setNewUnseen(false)
    }

    const singleNotificationClick=(e, type, notificationId)=>{
        e.preventDefault();
        // dispatch(thunks.markSingleUnread(type, notificationId));
        thunks.markSingleRead(type, notificationId);
        checkLocal();
        checkAnyUnread();
        if(!fullMessageId || (fullMessageId && fullMessageId.id !== notificationId)) setFullMessageId({type:type, id: notificationId})
        else setFullMessageId();
    }

    return(
        <div className ={styles["notification-preview-wrapper"]}>

            <PopoverTrigger
                popoverContent={
                    <div>
                        {pickupPoll && 
                            <div className={styles["orders-wrapper"]}>
                                {orderNotifications?.length > 0 && orderNotifications?.map((notification, i)=>(
                                    <div key={`pickup-notification-${notification.id}-${i}`} className={styles["each-notification"]}>
                                        <p onClick={(e)=>{singleNotificationClick(e,"orders", notification?.id)}}>
                                            <span className={styles["notification-subject"]}>
                                                {notification?.subject}
                                            </span>
                                            {notification.read ? 
                                                null 
                                            : 
                                                <span className={styles["unread-dot"]}>
                                                    {" "}
                                                </span>
                                            }
                                        </p>
                                        {fullMessageId?.id && fullMessageId?.type === "orders" && fullMessageId?.id === notification?.id &&
                                            <>
                                                <span className={styles["notification-message"]}>
                                                    {notification?.message}
                                                </span>

                                                <br />
                                                
                                                {notification?.link &&
                                                    <Button onClick={()=>history.push(notification.link)} className={styles["notification-link"]}>
                                                        {notification.linkMessage ? notification.linkMessage : "More Details"}
                                                    </Button>
                                                }
                                            </>
                                        }
                                    </div>
                                ))} 
                                {orderNotifications.length === 0 &&
                                    <div>
                                        You currently have no orders ready for pickup
                                    </div>
                                }
                            </div>
                        }

                    </div>
                }
                closeButton={true}
            >
                <div>
                    <i 
                        className={newUnseen ? `${styles["active-icon"]} far fa-bell` :  "far fa-bell"} 
                        onClick={handleNotificationClick}    
                    />
                    {hasAnyUnread &&
                        <span className={styles["unread-dot"]}></span>
                    }
                </div>
            </PopoverTrigger>
            
        </div>
    )
}
import React, { useState, useCallback } from 'react';
import { Button, Form, Row, Col, Container } from 'react-bootstrap'
import Cropper from 'react-easy-crop'

import getCroppedImg from './CropLogic'
import './EasyCrop.scss'

const EasyCrop = ({img, cancel, setImg, canvasHeight=null, canvasWidth=null, restrictPosition=true, showSizeControls=false, ...props}) => {

    const [ crop, setCrop ]=useState({ x: 0, y: 0 })
    const [ zoom, setZoom ]=useState(1)
    const [ croppedAreaPixels, setCroppedAreaPixels ]=useState(null)
    const [ showGrid, setShowGrid ]=useState(false)
    const [ customHeight, setCustomHeight ]=useState(null)
    const [ customWidth, setCustomWidth ]=useState(null)

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        const baseToBlob =async(base64)=>{
            return fetch(base64)
                .then(res => res.blob())
                .then(blob =>{
                    let croppedImg = URL.createObjectURL(blob);
                    setImg(croppedImg, blob);
                })
        }

        try {
            const croppedImg = await getCroppedImg(img,croppedAreaPixels);
            if(croppedImg) baseToBlob(croppedImg);
        } catch (ex) {
            console.error("Error from Easy Crop - ", ex);
            cancel();
        }
    }, [croppedAreaPixels, img,cancel,setImg]);

    const handleSize = (e, type) =>{
        if(typeof +e === 'number' && type === "H") setCustomHeight(+e);
        else if (type === "H") setCustomHeight(null);
        if(typeof +e === 'number' && type === "W") setCustomWidth(+e);
        else if (type === "W") setCustomWidth(null);

    }

    document.addEventListener('keydown', (e)=>{
        if(e.key === 'Escape'){
            props.hideShow();
        }
    })
  
    return (
        <div className="easy-crop-wrapper">
            <div className="cropper-container">
                <div className="easy-crop-container">
                    <Cropper
                        image={img}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        showGrid={showGrid}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        restrictPosition={restrictPosition}
                        zoomWithScroll={true}
                        cropSize={(canvasWidth && canvasHeight) ? 
                            {width: canvasWidth, height: canvasHeight} 
                            : 
                            ((customWidth && customHeight) ? 
                                {width: customWidth, height: customHeight} 
                                : 
                                null
                            )
                        }
                    />
                </div>
                <div className="control-container">
                    <Form>
                        <div className="form-check px-0">
                            <Form.Control
                                id="imgZoom"
                                type="range"
                                className="form-range"
                                min={.25}
                                max={25}
                                step={.25}
                                value={zoom}
                                onChange={(e) => setZoom(e.target.value)}
                            />
                            <label className="form-check-label mx-2" htmlFor="imgZoom">Zoom</label>
                        </div>
                        <Form.Check 
                            className="form-switch"
                            type="checkbox"
                            id="grid-toggle"
                            label="Grid Lines" 
                            onChange={()=>setShowGrid(!showGrid)}   
                        /> 
                    </Form>
                    {showSizeControls &&
                        <div>
                            <input 
                                name="height"
                                placeholder="Height"
                                onChange={(e)=>handleSize(e.target.value, "H")}
                            />
                            <input
                                name="width"
                                placeholder="Width"
                                onChange={(e)=>handleSize(e.target.value, "W")}
                            />
                        </div>
                    }
                    <Row>
                        <Col sm="auto p-0">
                            <Button variant="primary" onClick={showCroppedImage}>Save</Button>
                        </Col>
                        <Col sm="auto p-0">
                            <Button variant="light" onClick={cancel}>Cancel</Button>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    )
};

export default EasyCrop;
